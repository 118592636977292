var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "div",
        { staticClass: "content-box" },
        [
          _c(
            "el-input",
            {
              staticStyle: { width: "300px" },
              attrs: {
                maxlength: "15",
                placeholder: "搜索登录名/真实姓名/手机号",
              },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    "border-radius": "0 4px 4px 0",
                    "margin-right": "-5px",
                  },
                  attrs: {
                    slot: "suffix",
                    type: "primary",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.searchData },
                  slot: "suffix",
                },
                [_vm._v("搜索")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.addHandler },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-box" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableData },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "center",
                  width: "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "useraccount",
                  label: "登录名",
                  align: "center",
                  "min-width": "110",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "realName",
                  label: "真实姓名",
                  align: "center",
                  "min-width": "80",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "mobile",
                  label: "手机号",
                  align: "center",
                  "min-width": "110",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sum",
                  label: "金额",
                  align: "center",
                  "min-width": "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "royaltyPercent",
                  label: "分成比例",
                  formatter: _vm.royaltyPercent,
                  align: "center",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "设备数量",
                  align: "center",
                  "min-width": "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            staticStyle: { "font-weight": "bold" },
                            on: {
                              click: function ($event) {
                                return _vm.deviceHandler(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.pileCount) + "\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "useCount",
                  label: "使用次数",
                  align: "center",
                  "min-width": "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  align: "center",
                  "min-width": "100",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "state",
                  label: "状态",
                  align: "center",
                  "min-width": "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: { "active-value": "1", "inactive-value": "0" },
                          on: {
                            change: function ($event) {
                              return _vm.changeStateHandler($event, scope.row)
                            },
                          },
                          model: {
                            value: scope.row.state,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "state", $$v)
                            },
                            expression: "scope.row.state",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "340" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "editCell" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editRoyaltyPercentCardHandler(
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("设置比例")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editmenuHandler(scope.row)
                                  },
                                },
                              },
                              [_vm._v("配置岗位")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editCardHandler(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deteteHandlet(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.getTixianRecodsHandler(scope.row)
                                  },
                                },
                              },
                              [_vm._v("提现记录")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.resetPassword(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("重置密码")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.viewHandler(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page,
              "page-size": _vm.pageSize,
              total: _vm.total,
            },
            on: { "current-change": _vm.handleCurrentChange },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.popupModalTitle,
            visible: _vm.dialogVisible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                "label-width": "130px",
                rules: _vm.formRule,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "nav-form-item",
                  attrs: { label: "手机号", prop: "mobile" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { maxlength: "11", placeholder: "请输入手机号" },
                    model: {
                      value: _vm.formData.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "mobile", $$v)
                      },
                      expression: "formData.mobile",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "nav-form-item",
                  attrs: { label: "真实姓名", prop: "realName" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      maxlength: "5",
                      placeholder: "请输入姓名",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.realName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "realName", $$v)
                      },
                      expression: "formData.realName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "nav-form-item",
                  style: _vm.style,
                  attrs: { label: "登录用户名", prop: "useraccount" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { maxlength: "15", placeholder: "请输入登录用户名" },
                    model: {
                      value: _vm.formData.useraccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "useraccount", $$v)
                      },
                      expression: "formData.useraccount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "nav-form-item",
                  attrs: { label: "地址", prop: "address" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "请输入客户地址" },
                    model: {
                      value: _vm.formData.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "address", $$v)
                      },
                      expression: "formData.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "nav-form-item",
                  attrs: { label: "订购日期", prop: "buyDate" },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd",
                      format: "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.formData.buyDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "buyDate", $$v)
                      },
                      expression: "formData.buyDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isAdd,
                      expression: "isAdd",
                    },
                  ],
                  staticStyle: { "text-align": "center" },
                },
                [_vm._v("\n        新增代理商初始密码: admin123456\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confimHandler },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "配置分成比例",
            visible: _vm.dialogRoyaltyPercentVisible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogRoyaltyPercentVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formRoyaltyPercent",
              attrs: {
                model: _vm.fromRoyaltyPercentData,
                "label-width": "130px",
                rules: _vm.formRoyaltyPercentRule,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "nav-form-item",
                  attrs: { label: "代理商姓名", prop: "realName" },
                },
                [
                  _c("labe", [
                    _vm._v(_vm._s(_vm.fromRoyaltyPercentData.realName)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "nav-form-item",
                  attrs: { label: "代理商电话", prop: "mobile" },
                },
                [
                  _c("labe", [
                    _vm._v(_vm._s(_vm.fromRoyaltyPercentData.mobile)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "nav-form-item",
                  attrs: { label: "累计收益", prop: "accumulated" },
                },
                [
                  _c("labe", [
                    _vm._v(_vm._s(_vm.fromRoyaltyPercentData.accumulated)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "nav-form-item",
                  attrs: { label: "代理商余额", prop: "sum" },
                },
                [_c("labe", [_vm._v(_vm._s(_vm.fromRoyaltyPercentData.sum))])],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "nav-form-item",
                  attrs: { label: "分成比例", prop: "royaltyPercent" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { maxlength: "11" },
                    model: {
                      value: _vm.fromRoyaltyPercentData.royaltyPercent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.fromRoyaltyPercentData,
                          "royaltyPercent",
                          $$v
                        )
                      },
                      expression: "fromRoyaltyPercentData.royaltyPercent",
                    },
                  }),
                  _vm._v("%\n      "),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogRoyaltyPercentVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confimRoyaltyPercentHandler },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            id: "setOperatorsModal",
            title: "设置运营者",
            visible: _vm.setOperatorVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.setOperatorVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form_setPort",
              attrs: { model: _vm.setFormData, "label-width": "150px" },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "nav-form-item",
                  attrs: { label: "选择运营者", prop: "type" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择运营者" },
                      model: {
                        value: _vm.setFormData.userId,
                        callback: function ($$v) {
                          _vm.$set(_vm.setFormData, "userId", $$v)
                        },
                        expression: "setFormData.userId",
                      },
                    },
                    _vm._l(_vm.userData, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.realName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.setOperatorVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.setOperatorsForUser },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "配置岗位", visible: _vm.menuVisible, width: "40%" },
          on: {
            "update:visible": function ($event) {
              _vm.menuVisible = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: {
                multiple: "",
                "collapse-tags": "",
                placeholder: "请选择",
              },
              model: {
                value: _vm.roles,
                callback: function ($$v) {
                  _vm.roles = $$v
                },
                expression: "roles",
              },
            },
            _vm._l(_vm.AllParent, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.roleName, value: item.roleCode },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.menuVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.menuConfimHandler },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看代理商信息",
            visible: _vm.viewDisable,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.viewDisable = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                "label-width": "110px",
                rules: _vm.formRule,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "登录用户名" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入登录用户名",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.useraccount,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "useraccount", $$v)
                          },
                          expression: "formData.useraccount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "真实姓名" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "5",
                          placeholder: "请输入真实姓名",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.realName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "realName", $$v)
                          },
                          expression: "formData.realName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "nav-form-item", attrs: { label: "地址" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入地址",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "address", $$v)
                          },
                          expression: "formData.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "订购日期" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "5",
                          placeholder: "请输入订购日期",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.buyDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "buyDate", $$v)
                          },
                          expression: "formData.buyDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "手机号" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入手机号",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "mobile", $$v)
                          },
                          expression: "formData.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "nav-form-item", attrs: { label: "金额" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "5",
                          placeholder: "请输入金额",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.sum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "sum", $$v)
                          },
                          expression: "formData.sum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "所属岗位" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入所属岗位",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.roleArr,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "roleArr", $$v)
                          },
                          expression: "formData.roleArr",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "分成比例" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "5",
                          placeholder: "请输入分成比例",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.royaltyPercent,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "royaltyPercent", $$v)
                          },
                          expression: "formData.royaltyPercent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "设备数量" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入设备数量",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.pileCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "pileCount", $$v)
                          },
                          expression: "formData.pileCount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "使用次数" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "5",
                          placeholder: "请输入使用次数",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.useCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "useCount", $$v)
                          },
                          expression: "formData.useCount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "创建时间" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入创建时间",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.createTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "createTime", $$v)
                          },
                          expression: "formData.createTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.viewDisable = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.txShow, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.txShow = $event
            },
            close: function ($event) {
              _vm.txShow = false
            },
          },
        },
        [
          _c("span", [_vm._v("没有操作权限，请与管理员联系")]),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.txShow = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看代理商信息",
            visible: _vm.viewDisable,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.viewDisable = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                "label-width": "110px",
                rules: _vm.formRule,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "登录用户名" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入登录用户名",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.useraccount,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "useraccount", $$v)
                          },
                          expression: "formData.useraccount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "真实姓名" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "5",
                          placeholder: "请输入真实姓名",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.realName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "realName", $$v)
                          },
                          expression: "formData.realName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "nav-form-item", attrs: { label: "地址" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入地址",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "address", $$v)
                          },
                          expression: "formData.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "订购日期" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "5",
                          placeholder: "请输入订购日期",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.buyDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "buyDate", $$v)
                          },
                          expression: "formData.buyDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "手机号" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入手机号",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "mobile", $$v)
                          },
                          expression: "formData.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "nav-form-item", attrs: { label: "金额" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "5",
                          placeholder: "请输入金额",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.sum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "sum", $$v)
                          },
                          expression: "formData.sum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "所属岗位" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入所属岗位",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.roleArr,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "roleArr", $$v)
                          },
                          expression: "formData.roleArr",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "分成比例" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "5",
                          placeholder: "请输入分成比例",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.royaltyPercent,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "royaltyPercent", $$v)
                          },
                          expression: "formData.royaltyPercent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "设备数量" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入设备数量",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.pileCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "pileCount", $$v)
                          },
                          expression: "formData.pileCount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "使用次数" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "5",
                          placeholder: "请输入使用次数",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.useCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "useCount", $$v)
                          },
                          expression: "formData.useCount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "nav-form-item",
                      attrs: { label: "创建时间" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入创建时间",
                          disabled: true,
                        },
                        model: {
                          value: _vm.formData.createTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "createTime", $$v)
                          },
                          expression: "formData.createTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.viewDisable = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }