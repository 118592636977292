var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      { staticClass: "content-box" },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v("\n      代理商基本信息\n    "),
        ]),
        _vm._v(" "),
        _c(
          "el-descriptions",
          { staticClass: "margin-top", attrs: { column: 3, border: "" } },
          [
            _c(
              "template",
              { slot: "extra" },
              [
                _c("el-button", { attrs: { type: "primary" } }, [
                  _vm._v("操作"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _c("i", { staticClass: "el-icon-user" }),
                  _vm._v("\n          登录用户名\n        "),
                ]),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.accountDtailiInfo.userAccount) +
                    "\n      "
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _c("i", { staticClass: "el-icon-mobile-phone" }),
                  _vm._v("\n          真实姓名\n        "),
                ]),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.accountDtailiInfo.realName) +
                    "\n      "
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _c("i", { staticClass: "el-icon-location-outline" }),
                  _vm._v("\n          手机号\n        "),
                ]),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.accountDtailiInfo.mobile) +
                    "\n      "
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _c("i", { staticClass: "el-icon-tickets" }),
                  _vm._v("\n          设备数量\n        "),
                ]),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.accountDtailiInfo.deviceCount) +
                    "\n      "
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _c("i", { staticClass: "el-icon-office-building" }),
                  _vm._v("\n          代理商分成比例\n        "),
                ]),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.accountDtailiInfo.platFormRadio) +
                    "\n      "
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _c("i", { staticClass: "el-icon-office-building" }),
                  _vm._v("\n          平台分成比例\n        "),
                ]),
                _vm._v(
                  "\n        " +
                    _vm._s(100 - _vm.accountDtailiInfo.platFormRadio) +
                    "\n      "
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _c("i", { staticClass: "el-icon-office-building" }),
                  _vm._v("\n          金额（当前账户余额）\n        "),
                ]),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.accountDtailiInfo.amount) +
                    "\n      "
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _c("i", { staticClass: "el-icon-office-building" }),
                  _vm._v("\n          帐户状态\n        "),
                ]),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.accountDtailiInfo.state == 1 ? "启用" : "禁用") +
                    "\n      "
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [
                  _c("i", { staticClass: "el-icon-office-building" }),
                  _vm._v("\n          创建时间\n        "),
                ]),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.accountDtailiInfo.createTime) +
                    "\n      "
                ),
              ],
              2
            ),
          ],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-box" },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v("\n      分成者列表\n    "),
        ]),
        _vm._v(" "),
        _c(
          "el-table",
          { attrs: { data: _vm.tableData, border: "" } },
          [
            _vm._l(_vm.tableColumn, function (col) {
              return _c("el-table-column", {
                key: col.id,
                attrs: {
                  prop: col.prop,
                  label: col.label,
                  align: "center",
                  width: col.width,
                  "min-width": col.minWidth,
                },
              })
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "分成者状态", width: "100px", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            scope.row.holderStatus == 1 ? "启用" : "禁用"
                          ) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }