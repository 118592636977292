<template>
  <div class="main">
    <div class="content-box">
      <div class="title">
        代理商基本信息
      </div>
      <el-descriptions class="margin-top" :column="3" border>
        <template slot="extra">
          <el-button type="primary"  >操作</el-button>
        </template>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            登录用户名
          </template>
          {{ accountDtailiInfo.userAccount }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            真实姓名
          </template>
          {{ accountDtailiInfo.realName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            手机号
          </template>
          {{ accountDtailiInfo.mobile }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            设备数量
          </template>
          {{ accountDtailiInfo.deviceCount }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            代理商分成比例
          </template>
          {{ accountDtailiInfo.platFormRadio }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            平台分成比例
          </template>
          {{ 100 - accountDtailiInfo.platFormRadio }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            金额（当前账户余额）
          </template>
          {{ accountDtailiInfo.amount }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            帐户状态
          </template>
          {{ accountDtailiInfo.state == 1 ? "启用" : "禁用" }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            创建时间
          </template>
          {{ accountDtailiInfo.createTime }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="content-box">
      <div class="title">
        分成者列表
      </div>
      <el-table :data="tableData" border>
        <el-table-column
          v-for="col in tableColumn"
          :prop="col.prop"
          :key="col.id"
          :label="col.label"
          align="center"
          :width="col.width"
          :min-width="col.minWidth"
        >
        </el-table-column>
        <el-table-column label="分成者状态" width="100px" align="center">
          <template slot-scope="scope">
            {{ scope.row.holderStatus == 1 ? "启用" : "禁用" }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { accountDtail, shareholders } from "@/api/accountChildren";
import { tableColumn } from "./tableConfig";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      accountDtailiInfo: {},
      tableColumn: tableColumn,
      tableData: [],
      tableTotal: 0,
      form: {
        pageNo: 1,
        pageSize: 10,
        searchKey: "",
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getAccountDtail();
    this.getTableData();
  },
  methods: {
    getAccountDtail() {
      console.log(this.$route.query.userId);
      accountDtail(this.$route.query.userId).then((res) => {
        this.accountDtailiInfo = res.data;
        console.log(res);
      });
    },
    getTableData() {
      shareholders(this.$route.query.userId).then((res) => {
        this.tableData = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  margin-bottom: 10px;
}
::v-deep .el-descriptions__header {
  display: none;
}
</style>
