<template>
    <div class="notify-body">
        <div class="search-content">
            <el-form ref="searchFormRef" :model="searchForm" label-suffix=":" label-width="14px">
                <div style="display: flex;">
                    <el-row :gutter="8" type="flex" style="flex:1;flex-wrap:wrap;">
                        <el-col :span="8">
                            <el-form-item>
                                <div style="display: flex;">
                                    <span style="margin-right: 8px; white-space: nowrap;">消息标题</span>
                                    <el-input maxlength="11" v-model.trim="searchForm.title" placeholder="请输入"
                                        clearable></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="classify">
                                <div style="display: flex;">
                                    <span style="margin-right: 8px; white-space: nowrap;">发布状态</span>
                                    <el-select v-model="searchForm.stat" filterable placeholder="请选择"
                                        style="width: 100%;" clearable>
                                        <el-option v-for="(item, index) in statusList" :key="index" :label="item.type"
                                            :value="item.stat">
                                        </el-option>
                                    </el-select>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item ref="timeRange" prop="timeRange">
                                <div style="display: flex;">
                                    <span style="margin-right: 8px; white-space: nowrap;">发布时间</span>
                                    <el-date-picker v-model="searchForm.timeRange" type="daterange"
                                        :picker-options="pickerOptions" start-placeholder="开始日期" end-placeholder="结束日期"
                                        align="right" format="yyyy-MM-dd HH:mm:ss" :clearable="false"
                                        @change="handleDateTimeChange">
                                        <template slot="range-separator" scope="{ start, end }">
                                            <img src="@/assets/SwapRight.svg" alt="">
                                        </template>
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <div style="margin:0 12px; width: 1px;height: 32px; background-color: #E5E6EB;">
                        </div>
                    </el-row>
                    <el-row>
                        <div style="display: flex;">
                            <el-button @click="searchData" type="primary">查询</el-button>
                            <el-button @click="resetData" plain style="margin-left:8px;">重置</el-button>
                        </div>
                    </el-row>
                </div>
            </el-form>
        </div>
        <!-- 列表 -->
        <div class="table-list">
            <div class="add-list">
                <el-button @click="handleAddNotice" type="primary">新增</el-button>
            </div>
            <el-table v-loading="tableLoading" :data="tableData"
                :header-cell-style="{ background: '#F7F8FA !important', color: '#1D2129  !important' }"
                style="width: 100%">
                <el-table-column type="index" label="序号" width="60"></el-table-column>

                <el-table-column prop="title" label="消息标题" min-width="90" show-overflow-tooltip>
                    <template slot-scope="{row}">
                        <span v-if="row.title && row.title != ''">{{ row.title }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column prop="userNames" label="通知对象" min-width="90" show-overflow-tooltip>
                    <template slot-scope="{row}">
                        <span v-if="Array.isArray(row.userNames)">
                            {{ row.userNames.join(', ') }}
                        </span>
                        <span v-else>所有运营商</span>
                    </template>
                </el-table-column>
                <el-table-column prop="publishTime" label="发布时间" min-width="120" show-overflow-tooltip>
                    <template slot-scope="{row}">
                        <span v-if="row.publishTime && row.publishTime != ''">{{ row.publishTime }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column prop="stat" label="发布状态" min-width="120">
                    <template slot-scope="{row}">
                        <span v-show="row.stat == 0" class="table-state"
                            style="color: #F87700;background-color: rgba(248, 119, 0, 0.06);;">待发布</span>
                        <span v-show="row.stat == 1" class="table-state"
                            style="color: #057C98;background: rgba(5, 124, 152, 0.06);">已发布</span>
                        <span v-show="row.stat == 2" class="table-state"
                            style="color: #4E5969;background: rgba(29, 33, 41, 0.06);">已撤回
                            <el-tooltip class="item" effect="dark" :content="row.revokeReason" placement="top">
                                <img src="@/assets/notice/question-circle.svg"
                                    style="margin-left: 2px; width: 12px;height: 12px;cursor: pointer;" alt="">
                            </el-tooltip>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="120"></el-table-column>
                <el-table-column prop="createBy" label="创建人" min-width="80"></el-table-column>
                <el-table-column label="操作" :min-width="dynamicWidth">
                    <template slot-scope="scope">
                        <span @click="handleReadyDetail(scope.row)" type="primary"
                            style="margin-right: 12px; color: #057C98;cursor: pointer;">详情</span>
                        <!-- <span v-if="isOperator == 0" @click="handleActionNotice(0, scope.row)" type="primary"
                            style="margin-right: 12px;color: #057C98;cursor: pointer;">禁止显示</span>
                        <span v-else @click="handleEditNotice(scope.row)" type="primary"
                            style="margin-right: 12px;color: #057C98;cursor: pointer;">编辑</span> -->

                        <span v-if="scope.row.stat == 1" @click="handleActionNotice(0, scope.row)" type="primary"
                            style="margin-right: 12px;color: #057C98;cursor: pointer;">撤回</span>
                        <span v-if="scope.row.stat == 0" @click="handleEditNotice(scope.row)" type="primary"
                            style="margin-right: 12px;color: #057C98;cursor: pointer;">编辑</span>


                        <span v-if="(scope.row.stat == 1 || scope.row.stat == 2)" @click="handleCopyNotice(scope.row)"
                            type="primary" style="margin-right: 12px;color: #057C98;cursor: pointer;">复制</span>
                        <el-dropdown v-if="scope.row.stat == 0">
                            <span class="el-dropdown-link" style="color: #057C98;cursor: pointer;">
                                更多<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="handleCopyNotice(scope.row)"
                                    style="color:#86909C">复制</el-dropdown-item>
                                <el-dropdown-item v-if="scope.row.stat == 0"
                                    @click.native="handleActionNotice(1, scope.row)"
                                    style="color:#86909C">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="paginationObj.page" :page-size="paginationObj.listPageSize"
                layout="total, prev, pager, next,sizes, jumper" :total="paginationObj.total"></el-pagination>
        </div>

        <!-- 新增 & 编辑 & 复制 -->
        <div class="add-dialog">
            <el-dialog :title="isAddFlagVisibleTitle" :visible.sync="dialogVisibleAdd" @close="handleDialogAddClose"
                :close-on-click-modal="false" width="632px">
                <div class="donate-title"> <img src="@/assets/notice/Close-Circle-Fill.svg" alt="">
                    适用于在 web 端中通知运营商
                </div>
                <div class="donate-form">
                    <el-form ref="formDataAddRef" :model="formDataAdd" label-position="left" :rules="formDataAddRule">
                        <div>
                            <el-form-item class="nav-form-item" prop="title">
                                <div> <span style="color:#DF3737;">*</span> 消息标题</div>
                                <div class="list-item">
                                    <div class="item-input">
                                        <el-input maxlength="30" v-model.trim="formDataAdd.title" placeholder="请输入"
                                            show-word-limit clearable />
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item class="nav-form-item" style="margin-bottom: 0;">
                                <div> <span style="color:#DF3737;">*</span> 通知对象</div>
                                <div class="list-item" style="display: flex;align-items: center;">
                                    <div>
                                        <el-form-item prop="showUserIds">
                                            <el-radio-group v-model="formDataAdd.showUserIds"
                                                @change="changeNodeTypeUser">
                                                <el-radio :label="0"
                                                    style="margin-right: 16px;color: #4E5969;">全部运营商</el-radio>
                                                <el-radio :label="1"
                                                    style="margin-right: 8px;color: #4E5969;">指定运营商</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="item-input" v-if="isValidateUserView" style="flex: 1;">
                                        <el-form-item prop="operatorId">
                                            <el-select v-model="formDataAdd.operatorId" multiple placeholder="请选择 (可多选)"
                                                style="width: 100%;">
                                                <el-option v-for="(item, index) in operatorList" :key="index"
                                                    :label="item.realName" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item class="nav-form-item" style="margin-bottom: 0;">
                                <div> <span style="color:#DF3737;">*</span> 发布时间</div>
                                <div class="list-item" style="display: flex;align-items: center;">
                                    <div>
                                        <el-form-item prop="publishRadioTime">
                                            <el-radio-group v-model="formDataAdd.publishRadioTime"
                                                @change="changeNodeTypeTime">
                                                <el-radio :label="1"
                                                    style="margin-right: 16px;color: #4E5969;">立即发布</el-radio>
                                                <el-radio :label="0"
                                                    style="margin-right: 8px;color: #4E5969;">指定时间</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="item-input" v-if="isValidateTimeView" style="flex: 1;">
                                        <el-form-item prop="showPublishTime">
                                            <el-date-picker popper-class="no-atTheMoment"
                                                v-model="formDataAdd.showPublishTime" type="datetime"
                                                :picker-options="pickerOptions2" :default-value="defaultValue"
                                                @change="handleDateChangeDate" @blur="hanleBlurTime"
                                                format="yyyy-MM-dd HH:mm" placeholder="请选择">
                                            </el-date-picker>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item class="nav-form-item" prop="content" style="margin-bottom: 0;">
                                <div> <span style="color:#DF3737;">*</span> 消息内容</div>
                                <div class="list-item">
                                    <div>
                                        <editor v-model="formDataAdd.content" :min-height="120" />
                                    </div>
                                </div>
                            </el-form-item>

                            <el-form-item class="nav-form-item" label="" prop="fileId" style="margin-top: 8px;">
                                <div>
                                    <el-upload :limit="9" :action="uploadUrl" :headers="headers" :data="uploadData"
                                        list-type="picture-card" :on-change="handleStartChange"
                                        :on-success="handleFileSuccess" :on-exceed="handleImgExceed"
                                        :file-list="showImgList" multiple>

                                        <img slot="trigger" src="@/assets/notice/file-image.svg" alt=""
                                            style="margin-right:4px;vertical-align: middle;">
                                        <span slot="trigger">添加图片</span>

                                        <!-- <div slot="default" style="">
                                            <div slot="default" style="display: flex;">
                                                <img slot="default" src="@/assets/notice/icon-wrapper.svg" alt="">
                                                <span slot="default" style="white-space: nowrap;color: #057C98;font-size: 14px;font-weight: 400;">添加图片</span>
                                            </div>
                                        </div>  -->

                                        <!-- <div slot="default">添加图片</div> -->
                                        <div slot="file" slot-scope="{file}" style="width: 100%;height: 100%;">
                                            <img class="el-upload-list__item-thumbnail" :src="file.response.data.imgUrl"
                                                alt="" style="width: 140px;height: 105px;">
                                            <span class="el-upload-list__item-actions">
                                                <span v-if="!disabledImg" class="el-upload-list__item-delete"
                                                    @click="handleRemove(file)">
                                                    <i class="el-icon-delete"></i>
                                                </span>
                                            </span>
                                        </div>

                                    </el-upload>
                                </div>
                            </el-form-item>

                        </div>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleAdd = false">取 消</el-button>
                    <el-button type="primary" :disabled="isDisabled" @click="handleSureAdd">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 消息通知详情 -->
        <div class="notice-dialog">
            <el-dialog title="通知详情" :visible.sync="dialogVisibleDetail" width="600px">
                <div class="notice-box">
                    <div class="notice-list">
                        <div>消息通知标题</div>
                        <div>{{ showNoticeDetail.title }}</div>
                    </div>
                    <div class="notice-list" style="align-items: flex-start;">
                        <div style="line-height: 22px;">消息通知内容</div>
                        <div v-if="showNoticeDetail.compiledHtml && showNoticeDetail.compiledHtml != ''">
                            <div v-html="showNoticeDetail.compiledHtml"
                                style="text-align: left;white-space: normal;line-height: 22px;"></div>
                            <div class="image-gallery" style="margin-top: 8px;">
                                <img v-for="(image, index) in showNoticeDetail.images"
                                    style="margin-right: 8px; width: 140px; height: 105px;border-radius: 4px;cursor: pointer;"
                                    :key="index" :src="image" class="image-gallery-item"
                                    @click="handlePreviewImages(showNoticeDetail.images)">
                            </div>
                        </div>
                        <div v-else>无</div>
                    </div>
                    <div class="notice-list">
                        <div><span style="visibility: hidden;">通知</span>通知对象</div>
                        <div v-if="Array.isArray(showNoticeDetail.plotNames)">
                            {{ showNoticeDetail.plotNames.join(', ') }}
                        </div>
                        <div v-else>所有站点</div>
                    </div>
                    <div class="notice-list">
                        <div><span style="visibility: hidden;">发布</span>发布时间</div>
                        <div>{{ showNoticeDetail.publishTime }} </div>
                    </div>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleDetail = false">关 闭</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 删除 & 撤回 -->
        <div class="notice-action-dialog">
            <el-dialog title="" :visible.sync="dialogVisibleAction" width="480px">
                <div class="action-box">
                    <div><img src="@/assets/notice/icon-wrapper.svg" alt=""></div>
                    <div class="action-content">
                        <div class="notice-title"> {{ showNoticeAction.flag == 0 ? '确定撤回消息通知' : '确定删除消息通知' }} {{
                            `【${showNoticeAction.title}】` }}</div>
                        <div class="notice-desc">{{ showNoticeAction.flag == 0 ?
                            '撤回后运营商将不可见该消息' : '删除后该消息将被清除' }}</div>
                        <div class="disable-reason" v-if="showNoticeAction.flag == 0">
                            <el-input type="textarea" v-model="disableReason" :maxlength="30" placeholder="请输入撤回原因 (必填)"
                                rows="4" show-word-limit></el-input>
                        </div>
                    </div>

                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleAction = false">取 消</el-button>
                    <el-button type="primary" @click="handleSureAction">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 预览图片 -->
        <el-dialog :visible.sync="dialogVisibleImage" title="消息通知图片预览">
            <el-carousel class="image-swiper" :height="'100%'"
                :indicator-position="currentImages.length <= 1 ? 'none' : ''"
                :arrow="currentImages.length <= 1 ? 'never' : ''" :autoplay="false">
                <el-carousel-item v-for="(image, $i) in currentImages" :key="$i">
                    <el-image style="width: 100%; height: 100%" :src="image"
                        :preview-src-list="currentImages.map(v => v)" :initial-index="$i" :fit="'cover'">
                    </el-image>
                </el-carousel-item>
            </el-carousel>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleImage = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script setup>
import { reactive, ref, computed, watch } from 'vue'
import moment from "moment";

import {
    getNewsList,
    getUserNewsList,
    addNews,
    getOperatorList,
    readyViewNews,
    updateNews,
    delNews,
    revokeNews
} from "@/api/notify";
import { Message } from "element-ui";
import { getName, getToken } from '@/utils/auth.js'
//为0代表不可操作，1可操作属于运营人员
const getIsoperator = JSON.parse(getName())

const isOperator = ref(getIsoperator.userInfo.isOperator)
const dynamicWidth = computed(() => isOperator.value == 1 ? 110 : 80)
const tableLoading = ref(false);
const dialogVisibleAdd = ref(false);
const dialogVisibleDetail = ref(false);
const dialogVisibleAction = ref(false);
const isValidateTimeView = ref(false);
const isValidateUserView = ref(false);
const dialogVisibleImage = ref(false);
const currentImages = ref([]);
const isAddFlagVisible = ref(true);
const isAddFlagVisibleTitle = ref('新增站点公告');
const formDataAddRef = ref(null);
const searchFormRef = ref(null);
const operatorList = ref([]);
const showNoticeDetail = reactive({});
const showNoticeAction = reactive({});
const disableReason = ref('');
const disabledImg = ref(false);
const isDisabled = ref(false);
const nowDate = moment();
let selectableRange = ref('00:00:00-23:59:59')
const defaultValue = ref(new Date(new Date().getTime() + 10 * 60 * 1000));
const uploadUrl = ref(process.env.VUE_APP_BASE_API + "/file/uploadImg")
const showImgList = ref([])
const headers = reactive({
    token: getToken()
})
const uploadData = reactive({
    business: "advert"
})
const statusList = ref([
    {
        type: '待发布',
        stat: 0
    },
    {
        type: '已发布',
        stat: 1
    },
    {
        type: '已撤回',
        stat: 2
    }
])
const searchForm = reactive({
    operatorId: '',
    title: '',
    stat: '',
    timeRange: '',
    beginTime: '',
    endTime: '',
    pageNo: 1,
    pageSize: 20,
})
const pickerOptions = reactive({
    shortcuts: [
        {
            text: '本月',
            onClick(picker) {
                const end = new Date();
                const start = new Date(new Date().setDate(1)); // 当月第一天
                start.setHours(0, 0, 0, 0);
                picker.$emit('pick', [start, end]);
            }
        },
        {
            text: '本季',
            onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setMonth(start.getMonth() - start.getMonth() % 3, 1) // 计算季度开始月份的第一天
                start.setHours(0, 0, 0, 0);
                end.setMonth(((end.getMonth() / 3) + 1) * 3, 0) // 计算季度结束月份的最后一天
                end.setHours(23, 59, 59);
                picker.$emit('pick', [start, end]);
            }
        },
        {
            text: '本年',
            onClick(picker) {
                const end = new Date(); // 当前日期
                const start = new Date(end.getFullYear(), 0, 1); // 本年度的第一天
                end.setMonth(11, 31); // 本年度的最后一天，这里假设每个月的最后一天都是31号
                end.setHours(23, 59, 59);
                picker.$emit('pick', [start, end]);
            }
        }
    ],
    onPick: ({
        maxDate,
        minDate
    }) => {
        searchForm.timeRange = minDate.getTime()
        if (maxDate) {
            searchForm.timeRange = ''
        }
    }
})


const tableData = ref([]);
let tableParams = reactive({
    beginTime: '',
    endTime: '',
    operatorId: '',
    stat: '',
    title: '',
    pageNo: 1,
    pageSize: 20,
})
const paginationObj = reactive({
    page: 1,
    listPageSize: 20,
    total: 0,
})

//新增
const formDataAdd = reactive({
    imgs: [],
    timeRange: [],
    id: '',
    isPublish: '',
    content: '',
    showUserIds: 0,
    publishTime: '',
    showPublishTime: '',
    publishRadioTime: 1,
    remarks: '',
    title: '',
    userIds: [],
    operatorId: ''
})
const formDataAddRule = reactive({
    title: [{ required: true, message: "请输入消息标题", trigger: "blur" }],
    operatorId: [{ required: true, message: "请选择指定运营商", trigger: "blur" }],
    showPositions: [{ type: 'array', required: true, message: '请选择展示位置', trigger: 'change' }],
    showPublishTime: [{ required: true, message: "请选择指定时间", trigger: "blur" }],
    content: [{ required: true, message: "请输入消息内容", trigger: "blur" }],
})

function handleDateTimeChange(value) { //设置结束时间为23:59:59
    if (value && value.length === 2) {
        const [start, end] = value;
        if (end) {
            end.setHours(23, 59, 59, 999); // 设置时间为当天的23:59:59
        }
    }
}

// 计算一年后的日期
const oneYearLater = computed(() => {
    const now = new Date();
    const oneYearLater = new Date(now.setFullYear(now.getFullYear() + 1));
    return oneYearLater;
});

watch(() => formDataAdd.showPublishTime, (newValue, oldValue) => {
    if (!newValue) return
    // 前后同一天
    if (moment(newValue).format('YYYY-MM-DD') === moment(oldValue).format('YYYY-MM-DD')) {
        // 今天
        if (moment(newValue).format('YYYY-MM-DD') === nowDate.clone().format('YYYY-MM-DD')) {
            selectableRange.value = `${nowDate.clone().add(10, 'minutes').format('HH:mm:ss')}-23:59:59`
            if (!oldValue) {
                formDataAdd.showPublishTime = nowDate.clone().add(10, 'minutes').format('YYYY-MM-DD HH:mm')
            } else {
                if (moment(newValue).isBetween(moment(moment(nowDate).format('YYYY-MM-DD 00:00:00')), nowDate.clone().add(10, 'minutes'), undefined, '[]')) {
                    formDataAdd.showPublishTime = nowDate.clone().add(10, 'minutes').format('YYYY-MM-DD HH:mm')
                }
            }
        } else {
            selectableRange.value = '00:00:00-23:59:59'
            formDataAdd.showPublishTime = moment(newValue).format('YYYY-MM-DD HH:mm:ss')
        }
    } else {
        selectableRange.value = '00:00:00-23:59:59'
        formDataAdd.showPublishTime = moment(newValue).format('YYYY-MM-DD 00:00:00')
    }
})

const pickerOptions2 = computed(() => {
    return {
        disabledDate: (time) => {
          return moment(time).isBefore(nowDate.clone().add(-1, 'd')) || moment(time).isAfter(nowDate.clone().add(365, 'd'))
        },
        selectableRange: selectableRange.value
      }
})


const pickerOptions3 = computed(() => {
    let newVal = new Date(formDataAdd.showPublishTime)
    let selectableRange = '00:00:00 - 23:59:00'
    if (newVal && newVal.getFullYear() == new Date().getFullYear() &&
        newVal.getMonth() == new Date().getMonth() &&
        newVal.getDate() == new Date().getDate()
    ) {
        //如果为今天，则限制当前时间前的时间+10分钟不能选择。
        console.log(45679463);

        selectableRange = new Date().getHours() + ':' +
            (new Date().getMinutes() + 10) + ':00 - 23:59:00'

    } else {
        if (formDataAdd.showPublishTime != '') {
            const date = new Date(transformTime(formDataAdd.showPublishTime));
            date.setHours(0, 0, 0, 0);

            // const newDateTimeString = transformTime(formDataAdd.showPublishTime).replace(/(\d{4}-\d{2}-\d{2}) \d{2}:\d{2}:\d{2}/, '$1 00:00:00')
            // console.log(newDateTimeString,69666);

            // formDataAdd.showPublishTime = newDateTimeString
        }
    }

    return {
        selectableRange,
        // disabledDate(time) {
        //   return time.getTime() < Date.now() - 8.64e7
        // }
        disabledDate(time) {
            const today = new Date();
            today.setHours(0, 0, 0, 0); // 重置时间为当天的开始
            const yearLater = oneYearLater.value;
            yearLater.setHours(23, 59, 59, 999); // 设置时间为一年后的最后一天的最后一秒
            return (time.getTime() < today.getTime() || time.getTime() > yearLater.getTime()) && (time.getHours() === 0 && time.getMinutes() === 0)
        }
    }
})

function handleDateChangeDate(date, dateString) {
    if (!date) {
        formDataAdd.showPublishTime = ''
        return
    }; // 如果没有选择日期，则不执行任何操作

    const year = new Date().getFullYear(); // 获取四位数的年份
    const month = new Date().getMonth() + 1; // 获取月份 (0-11, 0 表示一月)
    const day = new Date().getDate(); // 获取日 (1-31)
    console.log(`今天：${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`);
    const isToday = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`

    let showDate = transformTime(date).split(' ')[0]
    if (isToday == showDate && transformTime(date).split(' ')[1] == '00:00:00') {
        formDataAdd.showPublishTime = ''
    }
}

function hanleBlurTime(date) {
    if (!date) {
        formDataAdd.showPublishTime = ''
        return
    }; // 如果没有选择日期，则不执行任何操作

    const year = new Date().getFullYear(); // 获取四位数的年份
    const month = new Date().getMonth() + 1; // 获取月份 (0-11, 0 表示一月)
    const day = new Date().getDate(); // 获取日 (1-31)
    console.log(`今天：${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`);
    const isToday = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`

    let showDate = transformTime(date).split(' ')[0]
    if (isToday == showDate && transformTime(date).split(' ')[1] == '00:00:00') {
        formDataAdd.showPublishTime = ''
    }

}
function getNewsListFn() { //获取消息数据-平台账号码
    getNewsList(tableParams).then((res) => {
        if (res.data && res.data.records.length > 0) {
            tableData.value = [...res.data.records];
        }
        paginationObj.total = res.data.total
    });
}
getNewsListFn()

function getOperatorListFn() { //获取运营商
    let param = {
        keyWord: '',
        pageNo: 0,
        pageSize: 10,
    }
    getOperatorList(param).then((res) => {
        if (res.data && res.data.records.length > 0) {
            operatorList.value = res.data.records;
        }
    });
}

function handleReadyDetail(row) { //查看通知详情
    readyViewNews({ id: row.id }).then(res => {
        // const regex = new RegExp('<img', 'gi')
        // res.data.content = res.data.content.replace(regex, `<img style="width: 140px; height: 105px"`)
        // extractImages(res.data, res.data.content)
        Object.assign(showNoticeDetail, res.data)
        showNoticeDetail.compiledHtml = res.data.content
        showNoticeDetail.images = res.data.imgs
        dialogVisibleDetail.value = true
    })
}
function extractImages(data, htmlString) { //富文本处理
    // 使用 DOM 操作提取所有图片
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    //图片处理
    data.images = Array.from(doc.querySelectorAll('img')).map(img => ({
        src: img.src,
        alt: img.alt,
    }));
    //文本处理
    // const showHtml = doc.body.innerHTML.replace(/<[^>]+>/g, '')
    // data.compiledHtml = showHtml
    // Object.assign(showNoticeDetail, data)
}

function handleStartChange(response, file) {
    showImgList.value = file
    isDisabled.value = !isDisabled.value
}

function handleFileSuccess(response, file, fileList) {
    // 成功上传图片后的处理逻辑
    console.log('图片上传成功', response, file, fileList);
    showImgList.value = fileList
    isDisabled.value = isDisabled.value
}
function handleImgExceed() {
    Message.warning('最多上传9张图片')
}
function handleRemove(file) {
    if (!isAddFlagVisible.value) {//编辑删除
        const index = showImgList.value.findIndex(f => f.uid === file.uid);
        if (index > -1) {
            showImgList.value.splice(index, 1);
        }
    } else {// 新增删除
        const index = showImgList.value.findIndex(f => f.name === file.name);
        if (index > -1) {
            showImgList.value.splice(index, 1);
        }
    }
}

// 标准日转化为年月日
function transformTime(date) {
    const d = new Date(date);
    return d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, '0') + "-" + d.getDate().toString().padStart(2, '0') + ' ' + d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0') + ':' + d.getSeconds().toString().padStart(2, '0');
}

function handleDateChange(value) {
    if (value && value[1]) {
        // 当用户选择了结束日期，将其时间设置为23:59:59
        const endTime = new Date(value[1].getTime());
        endTime.setHours(23, 59, 59);
        searchForm.timeRange[1] = endTime;
    }
}

function searchData() {
    if (searchForm.timeRange == '') {
        searchForm.beginTime = ''
        searchForm.endTime = ''
    } else {
        searchForm.beginTime = transformTime(searchForm.timeRange[0])
        searchForm.endTime = transformTime(searchForm.timeRange[1])
    }

    tableParams = {
        ...searchForm
    }
    tableData.value = []
    getNewsListFn()

}
function resetData() {
    searchFormRef.value.resetFields()
    searchForm.beginTime = ''
    searchForm.endTime = ''
    searchForm.stat = ''
    searchForm.title = ''
    searchForm.operatorId = ''
    tableParams = {
        ...searchForm
    }
    tableData.value = []
    getNewsListFn()
}

function changeNodeTypeUser(e) {
    if (e == 0) {
        isValidateUserView.value = false
    } else {
        getOperatorListFn()
        isValidateUserView.value = true
    }
}

function changeNodeTypeTime(e) {
    if (e == 0) {
        isValidateTimeView.value = true
    } else {
        isValidateTimeView.value = false
    }
}

function handleAddNotice() {
    isAddFlagVisibleTitle.value = '新增消息通知'
    isAddFlagVisible.value = true
    dialogVisibleAdd.value = true
}

function handleDialogAddClose() {
    formDataAdd.title = ''
    formDataAdd.content = ''
    formDataAdd.publishRadioTime = 1
    formDataAdd.showUserIds = 0
    formDataAdd.isPublish = 1
    formDataAdd.showPublishTime = ''
    formDataAdd.operatorId = ''
    showImgList.value = []
    isValidateUserView.value = false
    isValidateTimeView.value = false
    formDataAddRef.value.clearValidate();
    // formDataAddRef.value.resetFields();
}

const viewNoticeFn = (id) => {
    return new Promise(async (resolve, reject) => {
        return await readyViewNews({ id }).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

function handleActionNotice(flag, row) { //删除公告
    // flag:0撤回flag:1删除
    if (flag == 0) {
        row.flag = flag
        row.disableReason = ''
        Object.assign(showNoticeAction, row)

    } else {
        row.flag = flag
        Object.assign(showNoticeAction, row)

    }
    dialogVisibleAction.value = true
}

function handlePreviewImages(obj) { //预览图片
    currentImages.value = obj; // 设置当前要显示的图片列表
    console.log(currentImages);
    dialogVisibleImage.value = true; // 打开对话框
}

function handleCopyNotice(row) { //复制
    if (row.userIds && !row.userIds.includes(0)) { //指定运营商
        getOperatorListFn()
        formDataAdd.showUserIds = 1
        formDataAdd.operatorId = row.userIds
        isValidateUserView.value = true
    }

    viewNoticeFn(row.id).then(res => { //获取详情-消息内容
        row.content = res.data.content
        Object.assign(formDataAdd, row)
        if (res.data.imgs.length > 0) {//图片
            const showListArray = res.data.imgs.map(url => { return { response: { data: { imgUrl: url } } } })
            console.log(showListArray, 789679463);
            showImgList.value = showListArray
        }
    })

    if (row.publishTime != '') {//指定时间
        formDataAdd.publishRadioTime = 0
        formDataAdd.isPublish = 0
        formDataAdd.showPublishTime = ''
        isValidateTimeView.value = true
    }

    Object.assign(formDataAdd, row)
    isAddFlagVisibleTitle.value = '新增消息通知'
    isAddFlagVisible.value = true
    dialogVisibleAdd.value = true
}

function handleEditNotice(row) { //编辑
    console.log(row, 99933);

    if (row.userIds && !row.userIds.includes(0)) { //指定运营商
        getOperatorListFn()
        formDataAdd.showUserIds = 1
        formDataAdd.operatorId = row.userIds
        isValidateUserView.value = true
    }

    viewNoticeFn(row.id).then(res => { //获取详情-消息内容
        row.content = res.data.content
        Object.assign(formDataAdd, row)
        if (res.data.imgs.length > 0) {//图片
            const showListArray = res.data.imgs.map(url => { return { response: { data: { imgUrl: url } } } })
            console.log(showListArray, 789679463);
            showImgList.value = showListArray
        }
    })


    if (row.publishTime != '') { //指定时间
        formDataAdd.publishRadioTime = 0
        formDataAdd.isPublish = 0
        formDataAdd.showPublishTime = row.publishTime
        isValidateTimeView.value = true
    }

    Object.assign(formDataAdd, row)
    isAddFlagVisibleTitle.value = '编辑消息通知'
    isAddFlagVisible.value = false
    dialogVisibleAdd.value = true
}

function handleSureAdd() {
    if (isAddFlagVisible.value) { // 添加
        formDataAddRef.value.validate((valid) => {
            if (valid) {

                console.log(showImgList, '图片shuj');

                //图片处理
                formDataAdd.imgs = []
                if (showImgList.value.length > 0) {
                    showImgList.value.forEach(item => {
                        if (item.response && item.response.data) {
                            formDataAdd.imgs.push(item.response.data.imgUrl)
                        }
                    })
                }
                console.log(formDataAdd, '提交数据');
                if (formDataAdd.publishRadioTime == 1) { //立即发布
                    formDataAdd.publishTime = ''
                    formDataAdd.isPublish = 1
                } else {
                    formDataAdd.publishTime = transformTime(formDataAdd.showPublishTime)
                    formDataAdd.isPublish = 0
                }

                formDataAdd.userIds = []
                if (formDataAdd.showUserIds == 0) { //全部运营商
                    formDataAdd.userIds.push(0)
                } else {
                    formDataAdd.userIds = formDataAdd.operatorId
                }
                formDataAdd.id = ''

                addNews(formDataAdd)
                    .then((res) => {
                        if (res.message.code == 0) {
                            Message.success('操作成功')
                            showImgList.value = []
                            tableData.value = []
                            getNewsListFn()
                            dialogVisibleAdd.value = false
                        } else {
                            Message(res.message.message)
                        }
                    }).catch((e) => {
                        Message(e)
                    });
            }
        })
    } else {// 编辑
        formDataAddRef.value.validate((valid) => {
            if (valid) {
                formDataAdd.userIds = []
                if (formDataAdd.showUserIds == 0) { //全部运营商
                    formDataAdd.userIds.push(0)
                } else { //指定运营商
                    formDataAdd.userIds = formDataAdd.operatorId
                }

                if (formDataAdd.publishRadioTime == 1) { //立即发布
                    formDataAdd.isPublish = 1
                    formDataAdd.publishTime = ''
                } else {
                    formDataAdd.isPublish = 0
                    formDataAdd.publishTime = transformTime(formDataAdd.showPublishTime)
                }

                console.log(showImgList.value, 123456, formDataAdd);
                if (!Array.isArray(formDataAdd.imgs)) formDataAdd.imgs = []
                if (showImgList.value.length > 0) { //图片处理
                    showImgList.value.forEach(item => {
                        if (item.response && item.response.data) {
                            formDataAdd.imgs.push(item.response.data.imgUrl)
                        }
                    })
                }

                updateNews(formDataAdd)
                    .then((res) => {
                        if (res.message.code == 0) {
                            Message.success('操作成功')
                            tableData.value = []
                            getNewsListFn()
                            dialogVisibleAdd.value = false
                        } else {
                            Message(res.message.message)
                        }
                    }).catch((e) => {
                        Message(e)
                    });
            }
        })
    }
}

function handleSureAction() { //flag:0撤回
    if (showNoticeAction.flag == 0) {
        if (disableReason.value == '') {
            Message.info('撤回原因不能为空')
            return
        }
        let params = {
            id: showNoticeAction.id,
            revokeReason: disableReason.value
        }
        revokeNews(params).then((res) => {
            if (res.message.code == 0) {
                Message.success('操作成功')
                getNewsListFn()
                dialogVisibleAction.value = false
            } else {
                Message.error(res.message.message);
            }
        }).catch((e) => {
            Message(e)
        });
    } else {
        delNews({ id: showNoticeAction.id }).then((res) => {
            if (res.message.code == 0) {
                Message.success('操作成功')
                getNewsListFn()
                dialogVisibleAction.value = false
            } else {
                Message.error(res.message.message);
            }
        }).catch((e) => {
            Message.error(e)
        });
    }
}

function handleSizeChange(val) {
    paginationObj.listPageSize = val;
    tableParams.pageSize = val
    getNewsListFn()
}

function handleCurrentChange(val) {
    paginationObj.pageNo = val;
    paginationObj.page = val;
    tableParams.pageNo = val
    getNewsListFn()
}

</script>

<style lang="scss" scoped>
.notify-body {
    padding: 16px;
    background-color: #F7F8FA;

    .search-content {
        padding: 16px 16px 0 0;
        border-radius: 8px;
        background-color: #fff;
    }

    .table-list {
        margin-top: 12px;
        padding: 16px;
        background-color: #fff;


        .table-state {
            line-height: normal;
            display: inline-flex;
            align-items: center;
            padding: 4px 8px;
            border-radius: 4px;
            cursor: pointer;
            font-family: "PingFang SC";
            font-size: 12px;
            font-weight: 400;
            background: rgba(5, 124, 152, 0.06);
        }


        .add-list {
            margin-bottom: 12px;
        }
    }

    /**添加公告弹框样式 */
    .add-dialog {
        .donate-title {
            display: flex;
            align-items: center;
            padding: 9px 12px;
            margin-bottom: 16px;
            color: rgba(0, 0, 0, 0.65);
            font-family: "PingFang SC";
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
            border: 1px solid #62B7BF;
            background: #F2FBFC;

            img {
                margin-right: 4px;
                vertical-align: middle;
                width: 22px;
                height: 22px;
            }
        }

        /**弹框样式 */
        ::v-deep .el-dialog {
            border-radius: 8px;
        }

        ::v-deep .el-dialog__body {
            padding: 24px 20px 40px;
        }

        ::v-deep .el-dialog__title {
            color: #1D2129;
        }

        ::v-deep .el-dialog__header {
            color: var(--PC-, #1D2129);
            font-family: "PingFang SC";
            font-size: 16px;
            font-weight: 500;
            border-bottom: 1px solid #E5E6EB;
        }

        ::v-deep .el-dialog__footer {
            padding: 10px 20px 10px;
            border-top: 1px solid #E5E6EB;
        }
    }

    /**消息详情*/
    .notice-dialog {

        /**弹框样式 */
        ::v-deep .el-dialog {
            border-radius: 8px;
        }

        ::v-deep .el-dialog__body {
            padding: 24px;
        }

        ::v-deep .el-dialog__title {
            color: #1D2129;
        }

        ::v-deep .el-dialog__header {
            color: var(--PC-, #1D2129);
            font-family: "PingFang SC";
            font-size: 16px;
            font-weight: 500;
            border-bottom: 1px solid #E5E6EB;
        }

        ::v-deep .el-dialog__footer {
            padding: 10px 20px 10px;
            border-top: 1px solid #E5E6EB;
        }

        .notice-box {
            .notice-list {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                & div:nth-child(1) {
                    margin-right: 12px;
                    white-space: nowrap;
                    color: var(--PC-, #86909C);
                    text-align: right;
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-weight: 400;
                }

                & div:nth-child(2) {
                    color: var(--PC-, #1D2129);
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }

    /**删除&禁止显示 */
    .notice-action-dialog {

        /**弹框样式 */
        ::v-deep .el-dialog {
            border-radius: 8px;
        }

        ::v-deep .el-dialog__body {
            padding: 0;
        }

        ::v-deep .el-dialog__title {
            color: #1D2129;
        }

        ::v-deep .el-dialog__header {
            color: var(--PC-, #1D2129);
            font-family: "PingFang SC";
            font-size: 16px;
            font-weight: 500;
        }

        ::v-deep .el-dialog__footer {
            margin-top: 24px;
            padding: 10px 20px 20px;
        }

        .action-box {
            display: flex;
            padding: 0 24px;

            img {
                margin-right: 16px;
                width: 24px;
                height: 24px;
            }

            .action-content {
                flex: 1;

                .notice-title {
                    margin-bottom: 16px;
                    color: var(--PC-, #1D2129);
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                }

                .notice-desc {
                    color: var(--PC-, #4E5969);
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-weight: 400;
                }

                .disable-reason {
                    margin-top: 20px;
                }
            }
        }
    }

    .image-swiper {
        width: 400px;
        height: 300px;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

::v-deep .el-upload--picture-card {
    display: inline;
    line-height: 3;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    border: none;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 140px;
    height: 105px;
}
</style>