var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      { staticClass: "content-box" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: { border: "", data: _vm.tableData },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "realName",
                label: "用户",
                align: "center",
                "min-width": "100",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "userName",
                label: "用户名",
                align: "center",
                "min-width": "80",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "mobile",
                align: "center",
                label: "手机号",
                "min-width": "80",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "amount",
                label: "金额",
                align: "center",
                "min-width": "120",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "createTime",
                label: "时间",
                align: "center",
                "min-width": "120",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "status",
                label: "状态",
                align: "center",
                "min-width": "120",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-pagination", {
          attrs: {
            "current-page": _vm.page,
            "page-size": _vm.pageSize,
            total: _vm.total,
          },
          on: { "current-change": _vm.handleCurrentChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }