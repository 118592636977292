<template>
  <div class="main">
    <div class="content-box">
      <el-table v-loading="loading" border :data="tableData" style="width: 100%">
        <el-table-column prop="realName" label="用户" align="center" min-width="100"></el-table-column>
        <el-table-column prop="userName" label="用户名" align="center" min-width="80"></el-table-column>
        <el-table-column prop="mobile" align="center" label="手机号" min-width="80"></el-table-column>
        <el-table-column prop="amount" label="金额" align="center" min-width="120"></el-table-column>
        <el-table-column prop="createTime" label="时间" align="center" min-width="120"></el-table-column>
        <el-table-column prop="status" label="状态" align="center" min-width="120"></el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { getTixianRecods } from '@/api/operator'
import { getToken } from '@/utils/auth'
export default {
  data() {
    return {
      tableData: [],
      operatorId: this.$route.query.operatorId,
      page: 1,
      pageSize: 10,
      loading: true,
      total: 0
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.loading = true
      let param = {
        operatorId: this.operatorId,
        pageNo: this.page,
        pageSize: this.pageSize
      }
      getTixianRecods(param).then(res => {
        this.tableData = [...res.data.records]
        this.total = res.data.total
        this.loading = false
      })
    },
    handleCurrentChange(val) {
      this.page = val
      this.getDataList()
    }
  }
}
</script>
<style scoped>
.data {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
}
</style>