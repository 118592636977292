<template>
    <div class="notice-body">
        <div class="search-content">
            <el-form ref="searchFormRef" :model="searchForm" label-suffix=":" label-width="14px">
                <div style="display: flex;">
                    <el-row :gutter="8" type="flex" style="flex:1;flex-wrap:wrap;">
                        <el-col :span="8">
                            <el-form-item prop="mobile">
                                <div style="display: flex;">
                                    <span style="margin-right: 8px; white-space: nowrap;">公告名称</span>
                                    <el-input maxlength="11" v-model.trim="searchForm.title" placeholder="请输入"
                                        clearable></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item prop="classify">
                                <div style="display: flex;">
                                    <span style="margin-right: 8px; white-space: nowrap;">展示状态</span>
                                    <el-select v-model="searchForm.stat" filterable placeholder="请选择"
                                        style="width: 100%;" clearable>
                                        <el-option v-for="(item, index) in statusList" :key="index" :label="item.type"
                                            :value="item.stat">
                                        </el-option>
                                    </el-select>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item ref="timeRange" prop="timeRange">
                                <div style="display: flex;">
                                    <span style="margin-right: 8px; white-space: nowrap;">创建时间</span>
                                    <el-date-picker v-model="searchForm.timeRange" type="daterange"
                                        :picker-options="pickerOptions" start-placeholder="开始日期" end-placeholder="结束日期"
                                        align="right" format="yyyy-MM-dd HH:mm:ss" :clearable="false" @change="handleDateTimeChange">
                                        <template slot="range-separator" scope="{ start, end }">
                                            <img src="@/assets/SwapRight.svg" alt="">
                                        </template>
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="isOperator == 0">
                            <el-form-item prop="stat">
                                <div style="display: flex;">
                                    <span style="margin-right: 8px; white-space: nowrap;">运营商名称</span>
                                    <el-select v-model="searchForm.operatorId" filterable placeholder="请选择"
                                        style="width: 100%;" clearable>
                                        <el-option v-for="(item, index) in operatorList" :key="index"
                                            :label="item.realName" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <div style="margin:0 12px; width: 1px;background-color: #E5E6EB;"
                            :style="{ height: isOperator == 0 ? '80px' : '32px' }">
                        </div>
                    </el-row>
                    <el-row>
                        <div style="display: flex;">
                            <el-button @click="searchData" type="primary">查询</el-button>
                            <el-button @click="resetData" plain style="margin-left:8px;">重置</el-button>
                        </div>
                    </el-row>
                </div>
            </el-form>
        </div>
        <!-- 列表 -->
        <div class="table-list">
            <div class="add-list" v-if="isOperator == 1">
                <el-button @click="handleAddNotice" type="primary">新增</el-button>
            </div>
            <el-table v-loading="tableLoading" :data="tableData"
                :header-cell-style="{ background: '#F7F8FA !important', color: '#1D2129  !important' }"
                style="width: 100%">
                <el-table-column type="index" label="序号" width="60"></el-table-column>
                <el-table-column v-if="isOperator == 0" prop="userName" label="运营商名称" min-width="70"
                    show-overflow-tooltip>
                    <template slot-scope="{row}">
                        <span v-if="row.userName && row.userName != ''">{{ row.userName }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="公告名称" min-width="90" show-overflow-tooltip></el-table-column>
                <el-table-column prop="plotNames" label="适用电站" min-width="100" show-overflow-tooltip>
                    <template slot-scope="{row}">
                        <span v-if="Array.isArray(row.plotNames)">
                            {{ row.plotNames.join(', ') }}
                        </span>
                        <span v-else>所有站点</span>
                    </template>
                </el-table-column>
                <el-table-column prop="beginTime" label="展示时间" min-width="200">
                    <template slot-scope="{row}">
                        <div>
                            {{ row.beginTime }} 至 {{ row.endTime }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="stat" label="展示状态" min-width="60">
                    <template slot-scope="{row}">
                        <div v-if="row.stat == 0" class="table-state" style="color: #4E5969;">隐藏</div>
                        <div v-if="row.stat == 1" class="table-state" style="color: #057C98;">显示</div>
                        <div v-if="row.stat == 2" class="table-state" style="color: #DF3737;">禁止显示
                            <el-tooltip class="item" effect="dark" :content="row.disableReason" placement="top">
                                <img src="@/assets/notice/question-circle.svg"
                                    style="margin-left: 2px; width: 12px;height: 12px;cursor: pointer;" alt="">
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="100"></el-table-column>
                <el-table-column prop="createBy" label="创建人" min-width="50"></el-table-column>
                <el-table-column label="操作" :min-width="dynamicWidth">
                    <template slot-scope="scope">
                        <span @click="handleReadyDetail(scope.row)" type="primary"
                            style="margin-right: 12px; color: #057C98;cursor: pointer;">详情</span>
                        <span v-if="isOperator == 0 && scope.row.stat != 2" @click="handleActionNotice(0, scope.row)"
                            type="primary" style="margin-right: 12px;color: #057C98;cursor: pointer;">禁止显示</span>
                        <span v-if="isOperator == 1 && scope.row.stat != 2" @click="handleEditNotice(scope.row)"
                            type="primary" style="margin-right: 12px;color: #057C98;cursor: pointer;">编辑</span>
                        <el-dropdown v-if="isOperator == 1 && scope.row.stat != 2">
                            <span class="el-dropdown-link" style="color: #057C98;cursor: pointer;">
                                更多<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="handleCopyNotice(scope.row)"
                                    style="color:#86909C">复制</el-dropdown-item>
                                <el-dropdown-item @click.native="handleActionNotice(1, scope.row)"
                                    style="color:#86909C">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="paginationObj.page" :page-size="paginationObj.listPageSize"
                layout="total, prev, pager, next,sizes, jumper" :total="paginationObj.total"></el-pagination>
        </div>
        <!-- 新增 & 编辑 & 复制 -->
        <div class="add-dialog">
            <el-dialog :title="isAddFlagVisibleTitle" :visible.sync="dialogVisibleAdd" @close="handleDialogAddClose"
                :close-on-click-modal="false" width="600px">
                <div class="donate-title"> <img src="@/assets/notice/Close-Circle-Fill.svg" alt="">
                    适用于在小程序中通知车主站点相关的公告消息
                </div>
                <div class="donate-form">
                    <el-form ref="formDataAddRef" :model="formDataAdd" label-position="left" :rules="formDataAddRule">
                        <div>
                            <el-form-item class="nav-form-item" prop="title">
                                <div> <span style="color:#DF3737;">*</span> 公告名称</div>
                                <div class="list-item">
                                    <div class="item-input">
                                        <el-input maxlength="30" v-model.trim="formDataAdd.title"
                                            placeholder="仅用于后台辨识，移动端不展示" show-word-limit clearable />
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item class="nav-form-item" style="margin-bottom: 0;">
                                <div> <span style="color:#DF3737;">*</span> 适用电站</div>
                                <div class="list-item" style="display: flex;align-items: center;">
                                    <div>
                                        <el-form-item>
                                            <el-radio-group v-model="formDataAdd.showPlotIds" @change="changeNodeType">
                                                <el-radio :label="0"
                                                    style="margin-right: 16px;color: #4E5969;">所有站点</el-radio>
                                                <el-radio :label="1"
                                                    style="margin-right: 8px;color: #4E5969;">指定站点</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div class="item-input" v-if="isValidateView" style="flex: 1;">
                                        <el-form-item prop="appointPlotIds">
                                            <el-select v-model="formDataAdd.appointPlotIds" multiple placeholder="请选择"
                                                style="width: 100%;">
                                                <el-option v-for="(item, index) in plotOptions" :key="index"
                                                    :label="item.plotName" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item class="nav-form-item" style="margin-bottom: 0;">
                                <div> <span style="color:#DF3737;">*</span> 展示位置</div>
                                <div class="list-item">
                                    <div>
                                        <el-form-item prop="showPositions">
                                            <el-checkbox-group v-model="formDataAdd.showPositions">
                                                <el-checkbox label="站点详情页" value="1"></el-checkbox>
                                                <el-checkbox label="终端详情页" value="2"></el-checkbox>
                                                <el-checkbox label="首页站点栏" value="3"></el-checkbox>
                                                <el-checkbox label="充电中页" value="4"></el-checkbox>
                                            </el-checkbox-group>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item class="nav-form-item" style="margin-bottom: 0;">
                                <div> <span style="color:#DF3737;">*</span> 展示时间</div>
                                <div class="list-item">
                                    <div>
                                        <el-form-item prop="timeRange">
                                            <el-date-picker v-model="formDataAdd.timeRange" type="datetimerange"
                                                :picker-options="pickerOptions2" range-separator="至"
                                                start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                                                :clearable="false" format="yyyy-MM-dd HH:mm" @change="handleDateChange"
                                                style="width: 100%;">
                                            </el-date-picker>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item class="nav-form-item" style="margin-bottom: 0;">
                                <div> <span style="color:#DF3737;">*</span> 消息内容</div>
                                <div class="list-item">
                                    <div>
                                        <el-form-item prop="content">
                                            <el-input type="textarea" v-model="formDataAdd.content" :maxlength="150"
                                                placeholder="请输入(仅移动端展示)" rows="3" show-word-limit></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleAdd = false">取 消</el-button>
                    <el-button type="primary" @click="handleSureAdd">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <!-- 公告详情 -->
        <div class="notice-dialog">
            <el-dialog title="站点公告详情" :visible.sync="dialogVisibleDetail" width="600px">
                <div class="notice-box">
                    <div class="notice-list">
                        <div>公告标题</div>
                        <div>{{ showNoticeDetail.title }}</div>
                    </div>
                    <div class="notice-list">
                        <div>消息内容</div>
                        <div v-if="showNoticeDetail.content && showNoticeDetail.content != ''"
                            v-html="showNoticeDetail.content">
                        </div>
                        <div v-else>无</div>
                    </div>
                    <div class="notice-list">
                        <div>适用站点</div>
                        <div v-if="Array.isArray(showNoticeDetail.plotNames)">
                            {{ showNoticeDetail.plotNames.join(', ') }}
                        </div>
                        <div v-else>所有站点</div>
                    </div>
                    <div class="notice-list">
                        <div>展示位置</div>
                        <div>
                            <div v-if="Array.isArray(showNoticeDetail.positionNames)"
                                style="color: #1D2129;font-family: 'PingFang SC';font-size: 14px;font-weight: 400;">
                                {{ showNoticeDetail.positionNames.join(', ') }}
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div class="notice-list">
                        <div>展示时间</div>
                        <div>{{ showNoticeDetail.beginTime }} 至 {{ showNoticeDetail.endTime }}</div>
                    </div>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleDetail = false">关 闭</el-button>
                </span>
            </el-dialog>
        </div>

        <!-- 删除&禁止显示 -->
        <div class="notice-action-dialog">
            <el-dialog title="" :visible.sync="dialogVisibleAction" width="480px">
                <div class="action-box">
                    <div><img src="@/assets/notice/icon-wrapper.svg" alt=""></div>
                    <div class="action-content">
                        <div class="notice-title"> {{ showNoticeAction.flag == 0 ? '确定禁止显示公告' : '确定删除公告' }} {{
                            `【${showNoticeAction.title}】` }}</div>
                        <div class="notice-desc">{{ showNoticeAction.flag == 0 ?
                            '操作后移动端将不可见，运营商可查看详情，不支持其他操作' : '删除后该公告将被清除（移动端同步不可见）' }}</div>
                        <div class="disable-reason" v-if="showNoticeAction.flag == 0">
                            <el-input type="textarea" v-model="disableReason" :maxlength="30" placeholder="请输入禁止原因 (必填)"
                                rows="4" show-word-limit></el-input>
                        </div>
                    </div>

                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleAction = false">取 消</el-button>
                    <el-button type="primary" @click="handleSureAction">确 定</el-button>
                </span>
            </el-dialog>
        </div>

    </div>
</template>

<script setup>
import { reactive, ref, computed } from 'vue'

import {
    getOperatorList,
    getNoticeList,
    addNotice,
    getMemberPlots,
    delNotice,
    disableNotice,
    updateNotice,
    viewNotice
} from "@/api/notice";
import { Message } from "element-ui";
import { getName } from '@/utils/auth.js'
//为0代表不可操作，1可操作属于运营人员
const getIsoperator = JSON.parse(getName())

const isOperator = ref(getIsoperator.userInfo.isOperator)
const dynamicWidth = computed(() => isOperator.value == 1 ? 110 : 80)
const tableLoading = ref(false);
const dialogVisibleAdd = ref(false);
const dialogVisibleDetail = ref(false);
const dialogVisibleAction = ref(false);
const isValidateView = ref(false);
const isAddFlagVisible = ref(true);
const isAddFlagVisibleTitle = ref('新增站点公告');
const formDataAddRef = ref(null);
const searchFormRef = ref(null);
const operatorList = ref([]);
const showNoticeDetail = reactive({});
const showNoticeAction = reactive({});
const disableReason = ref('');
const statusList = ref([
    {
        type: '显示',
        stat: 1
    },
    {
        type: '隐藏',
        stat: 0
    }
])
const searchForm = reactive({
    operatorId: '',
    stat: '',
    timeRange: '',
    beginTime: '',
    endTime: '',
    pageNo: 1,
    pageSize: 20,
})
const pickerOptions = reactive({
    shortcuts: [
        {
            text: '本月',
            onClick(picker) {
                const end = new Date();
                const start = new Date(new Date().setDate(1)); // 当月第一天
                start.setHours(0, 0, 0, 0);
                picker.$emit('pick', [start, end]);
            }
        },
        {
            text: '本季',
            onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setMonth(start.getMonth() - start.getMonth() % 3, 1) // 计算季度开始月份的第一天
                start.setHours(0, 0, 0, 0);
                end.setMonth(((end.getMonth() / 3) + 1) * 3, 0) // 计算季度结束月份的最后一天
                end.setHours(23, 59, 59);
                picker.$emit('pick', [start, end]);
            }
        },
        {
            text: '本年',
            onClick(picker) {
                const end = new Date(); // 当前日期
                const start = new Date(end.getFullYear(), 0, 1); // 本年度的第一天
                end.setMonth(11, 31); // 本年度的最后一天，这里假设每个月的最后一天都是31号
                end.setHours(23, 59, 59);
                picker.$emit('pick', [start, end]);
            }
        }
    ],
    onPick: ({
        maxDate,
        minDate
    }) => {
        searchForm.timeRange = minDate.getTime()
        if (maxDate) {
            searchForm.timeRange = ''
        }
    }
})

function handleDateTimeChange(value){ //设置结束时间为23:59:59
    if (value && value.length === 2) {
    const [start, end] = value;
    if (end) {
      end.setHours(23, 59, 59, 999); // 设置时间为当天的23:59:59
    }
  }
}

let secondDay = 1000 * 60 * 60 * 24;
let _minDate = 0;
// const pickerOptions2 = reactive({
//     onPick({ minDate, maxDate }) {
//         _minDate = minDate.getTime();
//     },
//     disabledDate: (time) => {
//         if (_minDate > 0) {
//             //判断选择了一个日期之后,限定时间跨度为365天
//             return time.getTime() < Date.now() - 8.64e7 || (time.getTime() > _minDate + 365 * secondDay || time.getTime() < _minDate - 365 * secondDay || time.getTime() > time.now)
//         }
//         // 未选择时，单独处理禁止选择当天以后的数据
//         else {
//             return time.getTime() < Date.now() - 8.64e7;
//         }

//     }
// })

// 计算一年后的日期
const pickerOptions2 = computed(() => ({
    disabledDate(time) {
        // const today = new Date();
        // today.setHours(0, 0, 0, 0); // 将今天的时间设置为午夜，以便比较日期
        // return date.getTime() < today.getTime(); // 禁用今天之前的日期
        return time.getTime() < new Date(new Date().setHours(0, 0, 0, 0)).getTime();

    },
}));

const handleDateChange = (value) => {
    if (value && value.length === 2) {
        const startDate = new Date(value[0]);
        const endDate = new Date(value[1]);
        const maxDuration = 365 * 24 * 60 * 60 * 1000; // 365天的毫秒数

        if (endDate.getTime() - startDate.getTime() > maxDuration) {
            // 如果时间跨度超过365天，则重置选择
            formDataAdd.timeRange = [];
            Message('时间跨度不能超过365天');
        }

        if (startDate.getTime() <= new Date().getTime()) {
            // 如果选择的时间小于或等于当前时间，则重置选择
            formDataAdd.timeRange[0] = new Date().toISOString();
        }
    }
};

const tableData = ref([]);
let tableParams = reactive({
    beginTime: '',
    endTime: '',
    operatorId: '',
    stat: '',
    title: '',
    pageNo: 1,
    pageSize: 20,
})
const paginationObj = reactive({
    page: 1,
    listPageSize: 20,
    total: 0,
})

//新增
const plotOptions = ref([])
const formDataAdd = reactive({
    timeRange: [],
    beginTime: '',
    content: '',
    endTime: '',
    id: '',
    showPlotIds: 0,
    plotIds: [],
    appointPlotIds: [],
    showPositions: [],
    positions: [],
    remarks: '',
    title: '',
})

const formDataAddRule = reactive({
    title: [{ required: true, message: "请输入公告名称", trigger: "blur" }],
    appointPlotIds: [{ required: true, message: "请选择指定站点", trigger: "blur" }],
    showPositions: [{ type: 'array', required: true, message: '请选择展示位置', trigger: 'change' }],
    timeRange: [{ type: 'array', required: true, message: "请选择展示时间", trigger: "blur" }],
    content: [{ required: true, message: "请输入消息内容", trigger: "blur" }],

})
function getOperatorListFn() { //获取运营商
    let param = {
        keyWord: '',
        pageNo: 0,
        pageSize: 10,
    }
    getOperatorList(param).then((res) => {
        if (res.data && res.data.records.length > 0) {
            operatorList.value = res.data.records;
        }
    });
}
getOperatorListFn()

function getNoticeListFn() { //获取公告数据
    getNoticeList(tableParams).then((res) => {
        if (res.data && res.data.records.length > 0) {
            tableData.value = [...res.data.records];
        }
        paginationObj.total = res.data.total
    });
}
getNoticeListFn()

function getMemberPlotsFn() { // 获取指定站点
    let params = {
        keyWord: '',
        pageNo: 1,
        pageSize: 50,
    }
    getMemberPlots(params).then((res) => {
        if (res.message.code == 0) {
            plotOptions.value = res.data.records.filter(item => item.id = parseInt(item.id))
        } else {
            Message.error(res.message.message);
        }
    }).catch((e) => { });
}

function handleDialogAddClose() {
    formDataAddRef.value.clearValidate();
    formDataAddRef.value.resetFields();
    formDataAdd.timeRange = []
}

const viewNoticeFn = (id) => {
    return new Promise(async (resolve, reject) => {
        return await viewNotice({ id }).then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

function handleReadyDetail(row) { //查看公告
    viewNoticeFn(row.id).then(res => {
        Object.assign(showNoticeDetail, res.data)
        dialogVisibleDetail.value = true
    })
}

function handleAddNotice() {
    isAddFlagVisibleTitle.value = '新增站点公告'

    formDataAdd.showPlotIds = 0
    formDataAdd.appointPlotIds = []
    isValidateView.value = false
    isAddFlagVisible.value = true
    dialogVisibleAdd.value = true
}

function handleEditNotice(row) { //编辑
    row.showPositions = row.positionNames

    formDataAdd.appointPlotIds = []
    if (Array.isArray(row.plotIds) && !row.plotIds.includes(0)) { //指定站点

        getMemberPlotsFn()
        formDataAdd.appointPlotIds = row.plotIds
        formDataAdd.showPlotIds = 1
        isValidateView.value = true
    } else { //所有站点
        formDataAdd.appointPlotIds.push(row.plots)
        formDataAdd.showPlotIds = 0
        isValidateView.value = false
    }

    viewNoticeFn(row.id).then(res => { //获取详情-消息内容
        row.content = res.data.content
        Object.assign(formDataAdd, row)
    })
    formDataAdd.timeRange = [row.beginTime,row.endTime]

    isAddFlagVisibleTitle.value = '编辑站点公告'
    isAddFlagVisible.value = false
    dialogVisibleAdd.value = true
}

function handleSureAdd() {
    if (isAddFlagVisible.value) { // 添加公告
        
        formDataAddRef.value.validate((valid) => {
            if (valid) {
                formDataAdd.beginTime = transformTime(formDataAdd.timeRange[0])
                formDataAdd.endTime = transformTime(formDataAdd.timeRange[1])

                formDataAdd.plotIds = []
                formDataAdd.positions = []
                formDataAdd.showPositions.forEach((item, index) => {
                    if (item == '站点详情页') {
                        formDataAdd.positions[index] = 1
                    } else if (item == '终端详情页') {
                        formDataAdd.positions[index] = 2
                    } else if (item == '首页站点栏') {
                        formDataAdd.positions[index] = 3
                    } else {
                        formDataAdd.positions[index] = 4
                    }
                })

                if (formDataAdd.appointPlotIds.length > 0) {//判断使用电站
                    formDataAdd.plotIds = formDataAdd.appointPlotIds
                } else {
                    formDataAdd.plotIds.push(formDataAdd.showPlotIds)
                }

                formDataAdd.id = ''

                addNotice(formDataAdd)
                    .then((res) => {
                        if (res.message.code == 0) {
                            Message.success('操作成功')
                            tableData.value = []
                            getNoticeListFn()
                            dialogVisibleAdd.value = false
                        } else {
                            Message(res.message.message)
                        }
                    }).catch((e) => {
                        Message(e)
                    });
            }
        })
    } else {// 编辑公告
        formDataAddRef.value.validate((valid) => {
            if (valid) {

                formDataAdd.beginTime = transformTime(formDataAdd.timeRange[0])
                formDataAdd.endTime = transformTime(formDataAdd.timeRange[1])

                formDataAdd.plotIds = []
                formDataAdd.positions = []
                formDataAdd.showPositions.forEach((item, index) => {
                    if (item == '站点详情页') {
                        formDataAdd.positions[index] = 1
                    } else if (item == '终端详情页') {
                        formDataAdd.positions[index] = 2
                    } else if (item == '首页站点栏') {
                        formDataAdd.positions[index] = 3
                    } else {
                        formDataAdd.positions[index] = 4
                    }
                })

                if (formDataAdd.appointPlotIds.length > 0) {//判断使用电站
                    formDataAdd.plotIds = formDataAdd.appointPlotIds
                } else {
                    formDataAdd.plotIds.push(formDataAdd.showPlotIds)
                }
                updateNotice(formDataAdd)
                    .then((res) => {
                        if (res.message.code == 0) {
                            Message.success('操作成功')
                            tableData.value = []
                            getNoticeListFn()
                            dialogVisibleAdd.value = false
                        } else {
                            Message(res.message.message)
                        }
                    }).catch((e) => {
                        Message(e)
                    });
            }
        })
    }
}
function handleActionNotice(flag, row) { //删除公告
    // flag:0禁止flag:1删除
    if (flag == 0) {
        row.flag = flag
        row.disableReason = ''
        Object.assign(showNoticeAction, row)

    } else {
        row.flag = flag
        Object.assign(showNoticeAction, row)

    }
    dialogVisibleAction.value = true
}

// 删除 & 禁止确定
function handleSureAction() {
    if (showNoticeAction.flag == 0) {
        if (disableReason.value == '') {
            Message.info('禁止原因不能为空')
            return
        }
        let params = {
            id: showNoticeAction.id,
            disableReason: disableReason.value
        }
        disableNotice(params).then((res) => {
            if (res.message.code == 0) {
                Message.success('操作成功')
                getNoticeListFn()
                dialogVisibleAction.value = false
            } else {
                Message.error(res.message.message);
            }
        }).catch((e) => {
            Message(e)
        });
    } else {
        delNotice({ id: showNoticeAction.id }).then((res) => {
            if (res.message.code == 0) {
                Message.success('操作成功')
                getNoticeListFn()
                dialogVisibleAction.value = false
            } else {
                Message.error(res.message.message);
            }
        }).catch((e) => {
            Message.error(e)
        });
    }
}

//复制
function handleCopyNotice(row) {
    row.showPositions = row.positionNames

    formDataAdd.appointPlotIds = []
    if (Array.isArray(row.plotIds) && !row.plotIds.includes(0)) { //指定站点

        getMemberPlotsFn()
        formDataAdd.appointPlotIds = row.plotIds
        formDataAdd.showPlotIds = 1
        isValidateView.value = true
    } else {//所有站点
        formDataAdd.appointPlotIds.push(row.plots)
        formDataAdd.showPlotIds = 0
        isValidateView.value = false
    }

    viewNoticeFn(row.id).then(res => { //获取详情-消息内容
        row.content = res.data.content
        Object.assign(formDataAdd, row)
    })


    isAddFlagVisibleTitle.value = '新增站点公告'
    isAddFlagVisible.value = true
    dialogVisibleAdd.value = true
}

function changeNodeType(e) {
    if (e == 0) {
        formDataAdd.appointPlotIds = []
        formDataAdd.appointPlotIds.push(0)
        isValidateView.value = false
    } else {
        if (formDataAdd.appointPlotIds[0] == 0) formDataAdd.appointPlotIds = []
        getMemberPlotsFn()
        isValidateView.value = true
    }
}

// 标准日转化为年月日
function transformTime(date) {
    const d = new Date(date);
    return d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, '0') + "-" + d.getDate().toString().padStart(2, '0') + ' ' + d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0') + ':' + d.getSeconds().toString().padStart(2, '0');
}
function searchData() { //查询
    // if (!searchForm.timeRange) {
    //     Message('请选择创建时间')
    //     return
    // }
    // if (searchForm.timeRange[0] == '') {
    //     Message('请选择创建时间')
    //     return
    // }
    // if (searchForm.timeRange[1] == '') {
    //     Message('请选择创建时间')
    //     return
    // }
    if (searchForm.timeRange == '') {
        searchForm.beginTime = ''
        searchForm.endTime = ''
    } else {
        searchForm.beginTime = transformTime(searchForm.timeRange[0])
        searchForm.endTime = transformTime(searchForm.timeRange[1])
    }


    tableParams = {
        ...searchForm
    }
    tableData.value = []
    getNoticeListFn()
}

function resetData() { //重置
    searchFormRef.value.resetFields()
    searchForm.beginTime = ''
    searchForm.endTime = ''
    searchForm.stat = ''
    searchForm.title = ''
    searchForm.operatorId = ''
    tableParams = {
        ...searchForm
    }
    tableData.value = []
    getNoticeListFn()
}

function handleSizeChange(val) {
    paginationObj.listPageSize = val;
    tableParams.pageSize = val
    getNoticeListFn()
}

function handleCurrentChange(val) {
    paginationObj.pageNo = val;
    paginationObj.page = val;
    tableParams.pageNo = val
    getNoticeListFn()
}

</script>

<style lang="scss" scoped>
.notice-body {
    padding: 16px;
    background-color: #F7F8FA;

    .search-content {
        padding: 16px 16px 0 0;
        border-radius: 8px;
        background-color: #fff;
    }

    .table-list {
        margin-top: 12px;
        padding: 16px;
        background-color: #fff;


        .table-state {
            line-height: normal;
            display: inline-flex;
            align-items: center;
            padding: 4px 8px;
            border-radius: 4px;
            cursor: pointer;
            font-family: "PingFang SC";
            font-size: 12px;
            font-weight: 400;
            background: rgba(5, 124, 152, 0.06);
        }


        .add-list {
            margin-bottom: 12px;
        }
    }

    /**添加公告弹框样式 */
    .add-dialog {
        .donate-title {
            display: flex;
            align-items: center;
            padding: 9px 12px;
            margin-bottom: 16px;
            color: rgba(0, 0, 0, 0.65);
            font-family: "PingFang SC";
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
            border: 1px solid #62B7BF;
            background: #F2FBFC;

            img {
                margin-right: 4px;
                vertical-align: middle;
                width: 22px;
                height: 22px;
            }
        }

        /**弹框样式 */
        ::v-deep .el-dialog {
            border-radius: 8px;
        }

        ::v-deep .el-dialog__body {
            padding: 24px 20px 40px;
        }

        ::v-deep .el-dialog__title {
            color: #1D2129;
        }

        ::v-deep .el-dialog__header {
            color: var(--PC-, #1D2129);
            font-family: "PingFang SC";
            font-size: 16px;
            font-weight: 500;
            border-bottom: 1px solid #E5E6EB;
        }

        ::v-deep .el-dialog__footer {
            padding: 10px 20px 10px;
            border-top: 1px solid #E5E6EB;
        }
    }

    // 公告详情
    .notice-dialog {

        /**弹框样式 */
        ::v-deep .el-dialog {
            border-radius: 8px;
        }

        ::v-deep .el-dialog__body {
            padding: 24px;
        }

        ::v-deep .el-dialog__title {
            color: #1D2129;
        }

        ::v-deep .el-dialog__header {
            color: var(--PC-, #1D2129);
            font-family: "PingFang SC";
            font-size: 16px;
            font-weight: 500;
            border-bottom: 1px solid #E5E6EB;
        }

        ::v-deep .el-dialog__footer {
            padding: 10px 20px 10px;
            border-top: 1px solid #E5E6EB;
        }

        .notice-box {
            .notice-list {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                & div:nth-child(1) {
                    margin-right: 12px;
                    white-space: nowrap;
                    color: var(--PC-, #86909C);
                    text-align: right;
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-weight: 400;
                }

                & div:nth-child(2) {
                    color: var(--PC-, #1D2129);
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }

    // 删除&禁止显示
    .notice-action-dialog {

        /**弹框样式 */
        ::v-deep .el-dialog {
            border-radius: 8px;
        }

        ::v-deep .el-dialog__body {
            padding: 0;
        }

        ::v-deep .el-dialog__title {
            color: #1D2129;
        }

        ::v-deep .el-dialog__header {
            color: var(--PC-, #1D2129);
            font-family: "PingFang SC";
            font-size: 16px;
            font-weight: 500;
        }

        ::v-deep .el-dialog__footer {
            margin-top: 24px;
            padding: 10px 20px 20px;
        }

        .action-box {
            display: flex;
            margin-top: 10px;
            padding: 0 24px;

            img {
                margin-right: 16px;
                width: 24px;
                height: 24px;
            }

            .action-content {
                .notice-title {
                    margin-bottom: 16px;
                    color: var(--PC-, #1D2129);
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                }

                .notice-desc {
                    color: var(--PC-, #4E5969);
                    font-family: "PingFang SC";
                    font-size: 14px;
                    font-weight: 400;
                }

                .disable-reason {
                    margin-top: 20px;
                }
            }
        }
    }
}
</style>