<template>
  <div class="main">
    <div class="content-box">
      <el-input
        maxlength="15"
        style="width:300px;"
        placeholder="搜索登录名/真实姓名/手机号"
        v-model="search"
      >
        <el-button
          slot="suffix"
          @click="searchData"
          type="primary"
          icon="el-icon-search"
          style="border-radius:0 4px 4px 0;margin-right:-5px"
          >搜索</el-button
        >
      </el-input>

      <el-button
        @click="addHandler"
        type="primary"
        style="margin-left:20px;"
        icon="el-icon-plus"
        >新增</el-button
      >
      <!--            <el-button @click="setOpertors" type="primary" style="margin-left:20px;">设置运营者</el-button>-->
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <!-- <el-table-column type="selection" width="30"></el-table-column> -->
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="useraccount"
          label="登录名"
          align="center"
          min-width="110"
        ></el-table-column>
        <el-table-column
          prop="realName"
          label="真实姓名"
          align="center"
          min-width="80"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <!--                &lt;!&ndash;<el-table-column prop="userName" label="昵称" align="center" min-width="80"></el-table-column>&ndash;&gt;-->
        <!--                <el-table-column prop="address" label="地址" align="center" min-width="80" :show-overflow-tooltip="true"></el-table-column>-->
        <!--                <el-table-column prop="buyDate" label="订购日期" align="center" min-width="100"></el-table-column>-->
        <el-table-column
          prop="mobile"
          label="手机号"
          align="center"
          min-width="110"
        ></el-table-column>
        <el-table-column
          prop="sum"
          label="金额"
          align="center"
          min-width="60"
        ></el-table-column>
        <!--                <el-table-column prop="roleArr" label="所属岗位" align="center" min-width="80" :show-overflow-tooltip="true"></el-table-column>-->
        <el-table-column
          prop="royaltyPercent"
          label="分成比例"
          :formatter="royaltyPercent"
          align="center"
          min-width="80"
        ></el-table-column>

        <el-table-column label="设备数量" align="center" min-width="60">
          <template slot-scope="scope">
            <el-link style="font-weight: bold" @click="deviceHandler(scope.row)"
              >{{ scope.row.pileCount }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="useCount"
          label="使用次数"
          align="center"
          min-width="60"
        ></el-table-column>

        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          min-width="100"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="state"
          label="状态"
          align="center"
          min-width="60"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              active-value="1"
              @change="changeStateHandler($event, scope.row)"
              inactive-value="0"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="340">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button
                type="primary"
                @click="editRoyaltyPercentCardHandler(scope.row)"
                >设置比例</el-button
              >
              <el-button type="success" @click="editmenuHandler(scope.row)"
                >配置岗位</el-button
              >
              <el-button type="primary" @click="editCardHandler(scope.row)"
                >编辑</el-button
              >
              <el-button type="danger" @click="deteteHandlet(scope.row)"
                >删除</el-button
              >
              <el-button @click="getTixianRecodsHandler(scope.row)"
                >提现记录</el-button
              >
              <el-button @click="resetPassword(scope.row.id)"
                >重置密码</el-button
              >
              <el-button @click="viewHandler(scope.row)">查看</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      :title="popupModalTitle"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="130px"
        :rules="formRule"
      >
        <el-form-item class="nav-form-item" label="手机号" prop="mobile">
          <el-input
            maxlength="11"
            v-model="formData.mobile"
            style="width:300px;"
            placeholder="请输入手机号"
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="真实姓名" prop="realName">
          <el-input
            maxlength="5"
            v-model="formData.realName"
            style="width:300px;"
            placeholder="请输入姓名"
            clearable
          />
        </el-form-item>
        <el-form-item
          :style="style"
          class="nav-form-item"
          label="登录用户名"
          prop="useraccount"
        >
          <el-input
            maxlength="15"
            v-model="formData.useraccount"
            style="width:300px;"
            placeholder="请输入登录用户名"
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="地址" prop="address">
          <el-input
            v-model="formData.address"
            style="width:300px;"
            placeholder="请输入客户地址"
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="订购日期" prop="buyDate">
          <el-date-picker
            v-model="formData.buyDate"
            type="datetime"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <p v-show="isAdd" style="text-align: center;">
          新增代理商初始密码: admin123456
        </p>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confimHandler">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="配置分成比例"
      :visible.sync="dialogRoyaltyPercentVisible"
      width="40%"
    >
      <el-form
        ref="formRoyaltyPercent"
        :model="fromRoyaltyPercentData"
        label-width="130px"
        :rules="formRoyaltyPercentRule"
      >
        <el-form-item class="nav-form-item" label="代理商姓名" prop="realName">
          <labe>{{ fromRoyaltyPercentData.realName }}</labe>
        </el-form-item>
        <el-form-item class="nav-form-item" label="代理商电话" prop="mobile">
          <labe>{{ fromRoyaltyPercentData.mobile }}</labe>
        </el-form-item>
        <el-form-item class="nav-form-item" label="累计收益" prop="accumulated">
          <labe>{{ fromRoyaltyPercentData.accumulated }}</labe>
        </el-form-item>
        <el-form-item class="nav-form-item" label="代理商余额" prop="sum">
          <labe>{{ fromRoyaltyPercentData.sum }}</labe>
        </el-form-item>
        <el-form-item
          class="nav-form-item"
          label="分成比例"
          prop="royaltyPercent"
        >
          <el-input
            maxlength="11"
            v-model="fromRoyaltyPercentData.royaltyPercent"
            style="width:150px;"
          />%
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogRoyaltyPercentVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confimRoyaltyPercentHandler"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      id="setOperatorsModal"
      title="设置运营者"
      :visible.sync="setOperatorVisible"
      width="30%"
    >
      <el-form ref="form_setPort" :model="setFormData" label-width="150px">
        <el-form-item class="nav-form-item" label="选择运营者" prop="type">
          <el-select
            v-model="setFormData.userId"
            style="width:200px"
            placeholder="请选择运营者"
          >
            <el-option
              v-for="item in userData"
              :label="item.realName"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setOperatorVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="setOperatorsForUser">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="配置岗位" :visible.sync="menuVisible" width="40%">
      <el-select
        v-model="roles"
        multiple
        collapse-tags
        style="margin-left: 20px;"
        placeholder="请选择"
      >
        <el-option
          v-for="item in AllParent"
          :key="item.value"
          :label="item.roleName"
          :value="item.roleCode"
        ></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="menuVisible = false">取 消</el-button>
        <el-button type="primary" @click="menuConfimHandler">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看代理商信息" :visible.sync="viewDisable" width="700px">
      <el-form
        ref="form"
        :model="formData"
        label-width="110px"
        :rules="formRule"
      >
        <div style="display: flex">
          <el-form-item class="nav-form-item" label="登录用户名">
            <el-input
              maxlength="11"
              v-model="formData.useraccount"
              style="width:200px;"
              placeholder="请输入登录用户名"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="真实姓名">
            <el-input
              maxlength="5"
              v-model="formData.realName"
              style="width:200px;"
              placeholder="请输入真实姓名"
              :disabled="true"
            />
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item class="nav-form-item" label="地址">
            <el-input
              maxlength="11"
              v-model="formData.address"
              style="width:200px;"
              placeholder="请输入地址"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="订购日期">
            <el-input
              maxlength="5"
              v-model="formData.buyDate"
              style="width:200px;"
              placeholder="请输入订购日期"
              :disabled="true"
            />
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item class="nav-form-item" label="手机号">
            <el-input
              maxlength="11"
              v-model="formData.mobile"
              style="width:200px;"
              placeholder="请输入手机号"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="金额">
            <el-input
              maxlength="5"
              v-model="formData.sum"
              style="width:200px;"
              placeholder="请输入金额"
              :disabled="true"
            />
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item class="nav-form-item" label="所属岗位">
            <el-input
              maxlength="11"
              v-model="formData.roleArr"
              style="width:200px;"
              placeholder="请输入所属岗位"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="分成比例">
            <el-input
              maxlength="5"
              v-model="formData.royaltyPercent"
              style="width:200px;"
              placeholder="请输入分成比例"
              :disabled="true"
            />
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item class="nav-form-item" label="设备数量">
            <el-input
              maxlength="11"
              v-model="formData.pileCount"
              style="width:200px;"
              placeholder="请输入设备数量"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="使用次数">
            <el-input
              maxlength="5"
              v-model="formData.useCount"
              style="width:200px;"
              placeholder="请输入使用次数"
              :disabled="true"
            />
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item class="nav-form-item" label="创建时间">
            <el-input
              maxlength="11"
              v-model="formData.createTime"
              style="width:200px;"
              placeholder="请输入创建时间"
              :disabled="true"
            />
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewDisable = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="txShow" width="30%" @close="txShow = false">
      <span>没有操作权限，请与管理员联系</span>
      <span slot="footer">
        <!-- <el-button @click=" txShow = false" type="default">取消</el-button> -->
        <el-button type="primary" @click="txShow = false">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看代理商信息" :visible.sync="viewDisable" width="700px">
      <el-form
        ref="form"
        :model="formData"
        label-width="110px"
        :rules="formRule"
      >
        <div style="display: flex">
          <el-form-item class="nav-form-item" label="登录用户名">
            <el-input
              maxlength="11"
              v-model="formData.useraccount"
              style="width:200px;"
              placeholder="请输入登录用户名"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="真实姓名">
            <el-input
              maxlength="5"
              v-model="formData.realName"
              style="width:200px;"
              placeholder="请输入真实姓名"
              :disabled="true"
            />
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item class="nav-form-item" label="地址">
            <el-input
              maxlength="11"
              v-model="formData.address"
              style="width:200px;"
              placeholder="请输入地址"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="订购日期">
            <el-input
              maxlength="5"
              v-model="formData.buyDate"
              style="width:200px;"
              placeholder="请输入订购日期"
              :disabled="true"
            />
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item class="nav-form-item" label="手机号">
            <el-input
              maxlength="11"
              v-model="formData.mobile"
              style="width:200px;"
              placeholder="请输入手机号"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="金额">
            <el-input
              maxlength="5"
              v-model="formData.sum"
              style="width:200px;"
              placeholder="请输入金额"
              :disabled="true"
            />
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item class="nav-form-item" label="所属岗位">
            <el-input
              maxlength="11"
              v-model="formData.roleArr"
              style="width:200px;"
              placeholder="请输入所属岗位"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="分成比例">
            <el-input
              maxlength="5"
              v-model="formData.royaltyPercent"
              style="width:200px;"
              placeholder="请输入分成比例"
              :disabled="true"
            />
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item class="nav-form-item" label="设备数量">
            <el-input
              maxlength="11"
              v-model="formData.pileCount"
              style="width:200px;"
              placeholder="请输入设备数量"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item class="nav-form-item" label="使用次数">
            <el-input
              maxlength="5"
              v-model="formData.useCount"
              style="width:200px;"
              placeholder="请输入使用次数"
              :disabled="true"
            />
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item class="nav-form-item" label="创建时间">
            <el-input
              maxlength="11"
              v-model="formData.createTime"
              style="width:200px;"
              placeholder="请输入创建时间"
              :disabled="true"
            />
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewDisable = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getOperatorList,
  add,
  editOperator,
  delOperator,
  getAccumulatedAmount,
  setUpRoyaltyPercent,
  setOperatorsForUser,
  getAllUser,
  editOperatorStatus,
} from "@/api/operator";
import { getDataScopeList, getUserMenu } from "@/api/user";
import { getRoleSlect } from "@/api/role";
import { resetPassword, setUserRole } from "../../api/sysUser";
export default {
  data() {
    return {
      txShow: false,
      menuVisible: false,
      dialogVisible: false,
      viewDisable: false,
      setOperatorVisible: false,
      dialogRoyaltyPercentVisible: false,
      operatorId: "",
      date: "",
      userId: "",
      roles: [],
      isAdd: true,
      search: "",
      tableData: [],
      page: 1,
      pageSize: 10,
      loading: true,
      fromRoyaltyPercentData: {
        operatorId: "",
        realName: "",
        mobile: "",
        accumulated: "",
        sum: "",
        royaltyPercent: "",
      },
      userData: [],
      AllParent: [],
      formData: {
        isOperator: 1,
        mobile: "",
        realName: "",
        useraccount: "",
        userName: "",
        address: "",
        buyDate: "",
      },
      setFormData: {
        operatorIds: [],
        userId: "",
      },
      total: 0,
      popupModalTitle: "新增",
      style: "",
      options: [],
      formRule: {
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        useraccount: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        buyDate: [
          { required: true, message: "请输入订购日期", trigger: "blur" },
        ],
      },
      formRoyaltyPercentRule: {
        royaltyPercent: [
          { required: true, message: "请输入分成比例", trigger: "blur" },
        ],
      },
      menuList: [],
    };
  },
  created() {
    this.getDataList();
    this.getDataScopeList();
    this.getAllUser();
  },
  mounted() {
    getUserMenu().then((res) => {
      this.menuList = res.data;
      console.log(this.menuList);
    });
  },
  computed: {},
  methods: {
    editmenuHandler(row) {
      this.userId = row.id;
      let param = {
        id: row.id,
      };

      getRoleSlect(param).then((res) => {
        console.log(res);
        this.AllParent = [...res.data];
        this.roles = [...[]];
        res.data.forEach((element) => {
          if (element.check) {
            this.roles.push(element.roleCode);
          }
        });
      });
      this.menuVisible = true;
    },
    menuConfimHandler() {
      let param = {
        id: this.userId,
        roleCodes: this.roles,
      };

      setUserRole(param).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.menuVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.data);
        }
      });
    },
    resetPassword(userId) {
      console.log(userId);
      resetPassword({ userId: userId }).then((res) => {
        if (res.message.code === 0) {
          this.$message({
            message: "密码重置成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.message.message,
            type: "fail",
          });
        }
      });
    },
    royaltyPercent(row) {
      row.royaltyPercent = parseFloat(row.royaltyPercent || 0).toFixed(2) + "%";
      return row.royaltyPercent;
    },
    getAllUser() {
      getAllUser().then((res) => {
        this.userData = [...res.data];
      });
    },
    setOpertors() {
      this.setOperatorVisible = true;
    },
    changeStateHandler($event, row) {
      let param = {
        state: $event,
        id: row.id,
      };
      editOperatorStatus(param).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.data);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        }
      });
    },
    setOperatorsForUser() {
      if (!this.setFormData.userId) {
        this.$message({
          message: "请选择运营者",
          type: "fail",
        });
        return;
      }
      if (this.setFormData.operatorIds.length == 0) {
        this.$message({
          message: "请选择代理商",
          type: "fail",
        });
        return;
      }
      setOperatorsForUser(this.setFormData).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.data);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.setOperatorVisible = false;
          this.getDataList();
        }
      });
    },
    getTixianRecodsHandler(row) {
      const menuListFun = (menuList) => {
        menuList.forEach((item) => {
          if (item.menuName == "提现记录") {
            this.$router.push({
              path: "operator/tixianRecods",
              query: {
                operatorId: row.id,
              },
            });
          } else {
            this.txShow = null;
          }
          if (item.children !== null) {
            menuListFun(item.children);
          }
        });
      };
      menuListFun(this.menuList);
      if (this.txShow == null) {
        this.txShow = true;
      }
      console.log(this.txShow);
      // this.$router.push({
      //     path: 'operator/tixianRecods',
      //     query: {
      //         operatorId: row.id
      //     }
      // })
    },
    handleSelectionChange(val) {
      this.setFormData.operatorIds = [];
      val.forEach((el) => {
        this.setFormData.operatorIds.push(el.id);
      });
      console.log(this.setFormData.operatorIds);
    },
    deviceHandler(row) {
      this.$router.push({
        path: "/device/deviceList",
        query: {
          operatorId: row.id,
        },
      });
    },
    isOperator(row) {
      if (row.isOperator == 1) {
        return "是";
      } else {
        return "否";
      }
    },
    state(row) {
      if (row.state == 1) {
        return "启用";
      } else {
        return "禁用";
      }
    },
    getDataScopeList() {
      getDataScopeList().then((res) => {
        if (res.data && res.data.length > 0) {
          this.options = [...res.data];
        }
      });
    },
    searchData() {
      this.page = 1;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let param = {
        keyWord: this.search,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getOperatorList(param).then((res) => {
        if (res.message.status == 200) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
        }

        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    addHandler() {
      this.isAdd = true;
      this.dialogVisible = true;
      this.formData.isOperator = 1;
      this.formData.mobile = "";
      this.formData.realName = "";
      this.formData.userName = "";
      this.formData.address = "";
      this.formData.buyDate = "";
      this.popupModalTitle = "新增";
      this.style = "";
      this.formData.useraccount = "";
    },
    confimHandler() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.add();
          } else {
            this.editCard();
          }
        }
      });
    },
    confimRoyaltyPercentHandler() {
      this.$refs.formRoyaltyPercent.validate((valid) => {
        if (valid) {
          setUpRoyaltyPercent(this.fromRoyaltyPercentData).then((res) => {
            if (res.message.code == 0) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.getDataList();
              this.dialogRoyaltyPercentVisible = false;
            } else {
              this.$message.error(res.message.message);
            }
          });
        }
      });
    },
    lossCardHandler(row) {
      this.$confirm("确定挂失所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          cardNo: row.cardNo,
        };
        lossCard(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getDataList();
          } else {
            this.$message.error(res.data);
          }
        });
      });
    },
    editCardHandler(row) {
      this.isAdd = false;
      this.formData.isOperator = row.isOperator;
      this.formData.mobile = row.mobile;
      this.formData.realName = row.realName;
      this.formData.useraccount = row.useraccount;
      this.formData.userName = row.userName;
      this.formData.buyDate = row.buyDate;
      this.formData.address = row.address;
      this.formData.id = row.id;
      this.style = "display:none";
      this.popupModalTitle = "编辑";
      this.dialogVisible = true;
    },
    viewHandler(row) {
      // this.formData = row;
      // this.viewDisable = true
      this.$router.push({
        path: "/childrenAccount",
        query: {
          userId: row.id,
        },
      });
    },
    editRoyaltyPercentCardHandler(row) {
      this.fromRoyaltyPercentData.operatorId = row.id;
      this.fromRoyaltyPercentData.realName = row.realName;
      this.fromRoyaltyPercentData.mobile = row.mobile;
      this.fromRoyaltyPercentData.accumulated = row.accumulated || "0";
      this.fromRoyaltyPercentData.sum = row.sum;
      this.fromRoyaltyPercentData.royaltyPercent = row.royaltyPercent.replace(
        "%",
        ""
      );

      getAccumulatedAmount(this.fromRoyaltyPercentData).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.message.message);
        } else {
          this.fromRoyaltyPercentData.accumulated = res.data || 0;
          this.dialogRoyaltyPercentVisible = true;
        }
      });
    },
    editCard() {
      editOperator(this.formData).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.message.message);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        }
      });
    },
    add() {
      add(this.formData).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.message.message);
        }
      });
    },
    deteteHandlet(row) {
      this.$confirm("确定删除所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          id: row.id,
        };
        delOperator(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getDataList();
          } else {
            this.$message.error(res.data);
          }
        });
      });
    },
  },
};
</script>
<style scoped lang="scss"></style>
