var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "notice-body" }, [
    _c(
      "div",
      { staticClass: "search-content" },
      [
        _c(
          "el-form",
          {
            ref: "searchFormRef",
            attrs: {
              model: _setup.searchForm,
              "label-suffix": ":",
              "label-width": "14px",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { flex: "1", "flex-wrap": "wrap" },
                    attrs: { gutter: 8, type: "flex" },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { prop: "mobile" } }, [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "8px",
                                    "white-space": "nowrap",
                                  },
                                },
                                [_vm._v("公告名称")]
                              ),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: {
                                  maxlength: "11",
                                  placeholder: "请输入",
                                  clearable: "",
                                },
                                model: {
                                  value: _setup.searchForm.title,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _setup.searchForm,
                                      "title",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "searchForm.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { prop: "classify" } }, [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "8px",
                                    "white-space": "nowrap",
                                  },
                                },
                                [_vm._v("展示状态")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _setup.searchForm.stat,
                                    callback: function ($$v) {
                                      _vm.$set(_setup.searchForm, "stat", $$v)
                                    },
                                    expression: "searchForm.stat",
                                  },
                                },
                                _vm._l(
                                  _setup.statusList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.type,
                                        value: item.stat,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { ref: "timeRange", attrs: { prop: "timeRange" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "margin-right": "8px",
                                      "white-space": "nowrap",
                                    },
                                  },
                                  [_vm._v("创建时间")]
                                ),
                                _vm._v(" "),
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "daterange",
                                    "picker-options": _setup.pickerOptions,
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                    align: "right",
                                    format: "yyyy-MM-dd HH:mm:ss",
                                    clearable: false,
                                  },
                                  on: { change: _setup.handleDateTimeChange },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "range-separator",
                                      fn: function ({ start, end }) {
                                        return [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/SwapRight.svg"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _setup.searchForm.timeRange,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _setup.searchForm,
                                        "timeRange",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.timeRange",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _setup.isOperator == 0
                      ? _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c("el-form-item", { attrs: { prop: "stat" } }, [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-right": "8px",
                                        "white-space": "nowrap",
                                      },
                                    },
                                    [_vm._v("运营商名称")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _setup.searchForm.operatorId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _setup.searchForm,
                                            "operatorId",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.operatorId",
                                      },
                                    },
                                    _vm._l(
                                      _setup.operatorList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.realName,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-row", [
                  _c("div", {
                    staticStyle: {
                      margin: "0 12px",
                      width: "1px",
                      "background-color": "#E5E6EB",
                    },
                    style: { height: _setup.isOperator == 0 ? "80px" : "32px" },
                  }),
                ]),
                _vm._v(" "),
                _c("el-row", [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _setup.searchData },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "8px" },
                          attrs: { plain: "" },
                          on: { click: _setup.resetData },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "table-list" },
      [
        _setup.isOperator == 1
          ? _c(
              "div",
              { staticClass: "add-list" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _setup.handleAddNotice },
                  },
                  [_vm._v("新增")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _setup.tableLoading,
                expression: "tableLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _setup.tableData,
              "header-cell-style": {
                background: "#F7F8FA !important",
                color: "#1D2129  !important",
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "60" },
            }),
            _vm._v(" "),
            _setup.isOperator == 0
              ? _c("el-table-column", {
                  attrs: {
                    prop: "userName",
                    label: "运营商名称",
                    "min-width": "70",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.userName && row.userName != ""
                              ? _c("span", [_vm._v(_vm._s(row.userName))])
                              : _c("span", [_vm._v("-")]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3276092916
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "title",
                label: "公告名称",
                "min-width": "90",
                "show-overflow-tooltip": "",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "plotNames",
                label: "适用电站",
                "min-width": "100",
                "show-overflow-tooltip": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      Array.isArray(row.plotNames)
                        ? _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(row.plotNames.join(", ")) +
                                "\n                    "
                            ),
                          ])
                        : _c("span", [_vm._v("所有站点")]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "beginTime",
                label: "展示时间",
                "min-width": "200",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("div", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(row.beginTime) +
                            " 至 " +
                            _vm._s(row.endTime) +
                            "\n                    "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "stat", label: "展示状态", "min-width": "60" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.stat == 0
                        ? _c(
                            "div",
                            {
                              staticClass: "table-state",
                              staticStyle: { color: "#4E5969" },
                            },
                            [_vm._v("隐藏")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      row.stat == 1
                        ? _c(
                            "div",
                            {
                              staticClass: "table-state",
                              staticStyle: { color: "#057C98" },
                            },
                            [_vm._v("显示")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      row.stat == 2
                        ? _c(
                            "div",
                            {
                              staticClass: "table-state",
                              staticStyle: { color: "#DF3737" },
                            },
                            [
                              _vm._v("禁止显示\n                        "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: row.disableReason,
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      "margin-left": "2px",
                                      width: "12px",
                                      height: "12px",
                                      cursor: "pointer",
                                    },
                                    attrs: {
                                      src: require("@/assets/notice/question-circle.svg"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "createTime",
                label: "创建时间",
                "min-width": "100",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "createBy", label: "创建人", "min-width": "50" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", "min-width": _setup.dynamicWidth },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "12px",
                            color: "#057C98",
                            cursor: "pointer",
                          },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _setup.handleReadyDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v("详情")]
                      ),
                      _vm._v(" "),
                      _setup.isOperator == 0 && scope.row.stat != 2
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-right": "12px",
                                color: "#057C98",
                                cursor: "pointer",
                              },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _setup.handleActionNotice(0, scope.row)
                                },
                              },
                            },
                            [_vm._v("禁止显示")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _setup.isOperator == 1 && scope.row.stat != 2
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-right": "12px",
                                color: "#057C98",
                                cursor: "pointer",
                              },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _setup.handleEditNotice(scope.row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _setup.isOperator == 1 && scope.row.stat != 2
                        ? _c(
                            "el-dropdown",
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-dropdown-link",
                                  staticStyle: {
                                    color: "#057C98",
                                    cursor: "pointer",
                                  },
                                },
                                [
                                  _vm._v("\n                            更多"),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-arrow-down el-icon--right",
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      staticStyle: { color: "#86909C" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _setup.handleCopyNotice(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("复制")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      staticStyle: { color: "#86909C" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _setup.handleActionNotice(
                                            1,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-pagination", {
          attrs: {
            "current-page": _setup.paginationObj.page,
            "page-size": _setup.paginationObj.listPageSize,
            layout: "total, prev, pager, next,sizes, jumper",
            total: _setup.paginationObj.total,
          },
          on: {
            "size-change": _setup.handleSizeChange,
            "current-change": _setup.handleCurrentChange,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "add-dialog" },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: _setup.isAddFlagVisibleTitle,
              visible: _setup.dialogVisibleAdd,
              "close-on-click-modal": false,
              width: "600px",
            },
            on: {
              "update:visible": function ($event) {
                _setup.dialogVisibleAdd = $event
              },
              close: _setup.handleDialogAddClose,
            },
          },
          [
            _c("div", { staticClass: "donate-title" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/notice/Close-Circle-Fill.svg"),
                  alt: "",
                },
              }),
              _vm._v(
                "\n                适用于在小程序中通知车主站点相关的公告消息\n            "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "donate-form" },
              [
                _c(
                  "el-form",
                  {
                    ref: "formDataAddRef",
                    attrs: {
                      model: _setup.formDataAdd,
                      "label-position": "left",
                      rules: _setup.formDataAddRule,
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "nav-form-item",
                            attrs: { prop: "title" },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                { staticStyle: { color: "#DF3737" } },
                                [_vm._v("*")]
                              ),
                              _vm._v(" 公告名称"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "list-item" }, [
                              _c(
                                "div",
                                { staticClass: "item-input" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "30",
                                      placeholder:
                                        "仅用于后台辨识，移动端不展示",
                                      "show-word-limit": "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _setup.formDataAdd.title,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.formDataAdd,
                                          "title",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formDataAdd.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "nav-form-item",
                            staticStyle: { "margin-bottom": "0" },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                { staticStyle: { color: "#DF3737" } },
                                [_vm._v("*")]
                              ),
                              _vm._v(" 适用电站"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "list-item",
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            on: {
                                              change: _setup.changeNodeType,
                                            },
                                            model: {
                                              value:
                                                _setup.formDataAdd.showPlotIds,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.formDataAdd,
                                                  "showPlotIds",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formDataAdd.showPlotIds",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                staticStyle: {
                                                  "margin-right": "16px",
                                                  color: "#4E5969",
                                                },
                                                attrs: { label: 0 },
                                              },
                                              [_vm._v("所有站点")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio",
                                              {
                                                staticStyle: {
                                                  "margin-right": "8px",
                                                  color: "#4E5969",
                                                },
                                                attrs: { label: 1 },
                                              },
                                              [_vm._v("指定站点")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _setup.isValidateView
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "item-input",
                                        staticStyle: { flex: "1" },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "appointPlotIds" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  multiple: "",
                                                  placeholder: "请选择",
                                                },
                                                model: {
                                                  value:
                                                    _setup.formDataAdd
                                                      .appointPlotIds,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _setup.formDataAdd,
                                                      "appointPlotIds",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formDataAdd.appointPlotIds",
                                                },
                                              },
                                              _vm._l(
                                                _setup.plotOptions,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.plotName,
                                                      value: item.id,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "nav-form-item",
                            staticStyle: { "margin-bottom": "0" },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                { staticStyle: { color: "#DF3737" } },
                                [_vm._v("*")]
                              ),
                              _vm._v(" 展示位置"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "list-item" }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "showPositions" } },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          model: {
                                            value:
                                              _setup.formDataAdd.showPositions,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _setup.formDataAdd,
                                                "showPositions",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataAdd.showPositions",
                                          },
                                        },
                                        [
                                          _c("el-checkbox", {
                                            attrs: {
                                              label: "站点详情页",
                                              value: "1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-checkbox", {
                                            attrs: {
                                              label: "终端详情页",
                                              value: "2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-checkbox", {
                                            attrs: {
                                              label: "首页站点栏",
                                              value: "3",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-checkbox", {
                                            attrs: {
                                              label: "充电中页",
                                              value: "4",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "nav-form-item",
                            staticStyle: { "margin-bottom": "0" },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                { staticStyle: { color: "#DF3737" } },
                                [_vm._v("*")]
                              ),
                              _vm._v(" 展示时间"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "list-item" }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "timeRange" } },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "datetimerange",
                                          "picker-options":
                                            _setup.pickerOptions2,
                                          "range-separator": "至",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          align: "right",
                                          clearable: false,
                                          format: "yyyy-MM-dd HH:mm",
                                        },
                                        on: { change: _setup.handleDateChange },
                                        model: {
                                          value: _setup.formDataAdd.timeRange,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.formDataAdd,
                                              "timeRange",
                                              $$v
                                            )
                                          },
                                          expression: "formDataAdd.timeRange",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "nav-form-item",
                            staticStyle: { "margin-bottom": "0" },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                { staticStyle: { color: "#DF3737" } },
                                [_vm._v("*")]
                              ),
                              _vm._v(" 消息内容"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "list-item" }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "content" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          maxlength: 150,
                                          placeholder: "请输入(仅移动端展示)",
                                          rows: "3",
                                          "show-word-limit": "",
                                        },
                                        model: {
                                          value: _setup.formDataAdd.content,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _setup.formDataAdd,
                                              "content",
                                              $$v
                                            )
                                          },
                                          expression: "formDataAdd.content",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _setup.dialogVisibleAdd = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _setup.handleSureAdd },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "notice-dialog" },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "站点公告详情",
              visible: _setup.dialogVisibleDetail,
              width: "600px",
            },
            on: {
              "update:visible": function ($event) {
                _setup.dialogVisibleDetail = $event
              },
            },
          },
          [
            _c("div", { staticClass: "notice-box" }, [
              _c("div", { staticClass: "notice-list" }, [
                _c("div", [_vm._v("公告标题")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_setup.showNoticeDetail.title))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "notice-list" }, [
                _c("div", [_vm._v("消息内容")]),
                _vm._v(" "),
                _setup.showNoticeDetail.content &&
                _setup.showNoticeDetail.content != ""
                  ? _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_setup.showNoticeDetail.content),
                      },
                    })
                  : _c("div", [_vm._v("无")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "notice-list" }, [
                _c("div", [_vm._v("适用站点")]),
                _vm._v(" "),
                Array.isArray(_setup.showNoticeDetail.plotNames)
                  ? _c("div", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_setup.showNoticeDetail.plotNames.join(", ")) +
                          "\n                    "
                      ),
                    ])
                  : _c("div", [_vm._v("所有站点")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "notice-list" }, [
                _c("div", [_vm._v("展示位置")]),
                _vm._v(" "),
                _c("div", [
                  Array.isArray(_setup.showNoticeDetail.positionNames)
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#1D2129",
                            "font-family": "'PingFang SC'",
                            "font-size": "14px",
                            "font-weight": "400",
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _setup.showNoticeDetail.positionNames.join(", ")
                              ) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _c("div", [_vm._v("-")]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "notice-list" }, [
                _c("div", [_vm._v("展示时间")]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    _vm._s(_setup.showNoticeDetail.beginTime) +
                      " 至 " +
                      _vm._s(_setup.showNoticeDetail.endTime)
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _setup.dialogVisibleDetail = false
                      },
                    },
                  },
                  [_vm._v("关 闭")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "notice-action-dialog" },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "",
              visible: _setup.dialogVisibleAction,
              width: "480px",
            },
            on: {
              "update:visible": function ($event) {
                _setup.dialogVisibleAction = $event
              },
            },
          },
          [
            _c("div", { staticClass: "action-box" }, [
              _c("div", [
                _c("img", {
                  attrs: {
                    src: require("@/assets/notice/icon-wrapper.svg"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "action-content" }, [
                _c("div", { staticClass: "notice-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _setup.showNoticeAction.flag == 0
                          ? "确定禁止显示公告"
                          : "确定删除公告"
                      ) +
                      " " +
                      _vm._s(`【${_setup.showNoticeAction.title}】`)
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "notice-desc" }, [
                  _vm._v(
                    _vm._s(
                      _setup.showNoticeAction.flag == 0
                        ? "操作后移动端将不可见，运营商可查看详情，不支持其他操作"
                        : "删除后该公告将被清除（移动端同步不可见）"
                    )
                  ),
                ]),
                _vm._v(" "),
                _setup.showNoticeAction.flag == 0
                  ? _c(
                      "div",
                      { staticClass: "disable-reason" },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            maxlength: 30,
                            placeholder: "请输入禁止原因 (必填)",
                            rows: "4",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _setup.disableReason,
                            callback: function ($$v) {
                              _setup.disableReason = $$v
                            },
                            expression: "disableReason",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _setup.dialogVisibleAction = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _setup.handleSureAction },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }